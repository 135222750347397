import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import ThemeContext from '../../../context/ThemeContext';

const SlideStyles = styled.div((props) => ({
  h1: {
    fontSize: props.theme.fontSize[24],
    lineHeight: props.theme.lineHeight[24],
    fontWeight: 200,
    textTransform: 'uppercase',
    marginBottom: props.theme.size[4],
    color: props.theme.colors[props.theme.mode].foreground[50],
  },
  h2: {
    fontSize: props.theme.fontSize[18],
    lineHeight: props.theme.lineHeight[18],
    fontWeight: 500,
    marginBottom: props.theme.size[4],
    color: props.theme.colors[props.theme.mode].foreground[50],
  },
  h3: {
    fontSize: props.theme.fontSize[16],
    lineHeight: props.theme.lineHeight[16],
    fontWeight: 700,
    marginBottom: props.theme.size[4],
    color: props.theme.colors[props.theme.mode].foreground[50],
  },
  p: {
    fontSize: props.theme.fontSize[6],
    lineHeight: props.theme.lineHeight[6],
    marginBottom: props.theme.size[4],
    color: props.theme.colors[props.theme.mode].foreground[0],
  },
  img: {
    maxWidth: '100%',
    marginBottom: props.theme.size[4],
  },
  ul: {
    fontSize: props.theme.fontSize[6],
    lineHeight: props.theme.lineHeight[6],
    marginBottom: props.theme.size[2],
  },
  li: {
    position: 'relative',
    fontSize: props.theme.fontSize[6],
    lineHeight: props.theme.lineHeight[6],
    paddingBottom: props.theme.size[8],
    color: props.theme.colors[props.theme.mode].foreground[0],
    '&:after': {
      content: '""',
      opacity: 0.2,
      background: props.theme.colors[props.theme.mode].foreground[0],
      width: props.theme.size[4],
      height: `calc(${props.theme.size[1]} * 0.5)`,
      left: '50%',
      position: 'absolute',
      bottom: props.theme.size[4],
      transform: 'translate(-50%, -50%)',
    },
    '&:last-child:after': {
      display: 'none',
    },
  },
}));

const Slide = (props) => {
  // Set update loop
  useEffect(() => {}, []);

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <SlideStyles theme={theme} data-testid='Slide'>
          {props.children}
        </SlideStyles>
      )}
    </ThemeContext.Consumer>
  );
};

Slide.propTypes = {};

Slide.defaultProps = {};

export default Slide;
