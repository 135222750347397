import React, { useState } from 'react';
import JSONPretty from 'react-json-pretty';
import Slider from 'rc-slider';

import Palette from '../Colors/Palette/Palette';
import Typography from '../Typography/Typography';
import { HomeScreen } from '../../pages/Home/Home';
import { Columns, Column2, Controls } from './Styles';

import Button from '../Button/Button';

export function RulesColor(props) {
  const [showControls, setShowControls] = useState(false);

  const exampleColor1 = {
    color: [props.theme.colors[props.theme.mode].primary[50]],
    count: 21,
    domain: [0, 100],
  };

  const exampleColor2 = {
    color: [props.theme.colors[props.theme.mode].primary[50]],
    count: 21,
    domain: [0, 100],
    contrast: [props.settings.contrast1, props.settings.contrast2],
    spin: [props.settings.spin1, props.settings.spin2],
  };

  const exampleColor3 = {
    color: [
      props.theme.colors[props.theme.mode].primary[0],
      props.theme.colors[props.theme.mode].secondary[50],
      props.theme.colors[props.theme.mode].accent[80],
    ],
    count: 21,
    domain: [0, 100],
  };

  return (
    <HomeScreen theme={props.theme}>
      <div className='container'>
        <Typography>
          <i
            className='fal fa-2x fa-palette'
            style={{ marginBottom: props.theme.size[2] }}
          ></i>
          <h2>Color</h2>
          <Columns theme={props.theme}>
            <Column2 theme={props.theme}>
              <h3>Rules</h3>
              <ul>
                <li>
                  Text color must have a contrast ratio greater than 4.5:1
                </li>
                <li>Colors add context to the UX</li>
              </ul>
            </Column2>
            <Column2 theme={props.theme}>
              <h3>Constraints</h3>
              <ul>
                <li>Color Palettes must contain 21 colors</li>
                <li>Colors values are between [0, 100]</li>
                <li>Color names must increment by 50</li>
                <li>Colors gradient from darkest (0) to lightest (100)</li>
              </ul>
            </Column2>
          </Columns>
          {showControls && (
            <Controls theme={props.theme}>
              <div className='controls-header'>Color</div>
              <div className='controls-actions'>
                <Button
                  onClick={(e) => props.settings.resetTheme()}
                  square={true}
                >
                  <i className='fal fa-sync'></i>
                </Button>
                <Button onClick={() => setShowControls(false)} square={true}>
                  <i className='fal fa-close'></i>
                </Button>
              </div>
              <h4>Primary</h4>
              <div className='control-group'>
                <div className='label'>
                  <label>Hue</label>
                  <div>{props.settings.hue}</div>
                </div>
                <Slider
                  min={0}
                  max={360}
                  onChange={(e) => {
                    props.settings.setHue(Number(e));
                  }}
                  value={props.settings.hue}
                />
              </div>

              <div className='control-group'>
                <div className='label'>
                  <label>Saturation</label>
                  <div>{props.settings.saturation}</div>
                </div>
                <Slider
                  min={0}
                  max={100}
                  onChange={(e) => {
                    props.settings.setSaturation(Number(e));
                  }}
                  value={props.settings.saturation}
                />
              </div>

              <div className='control-group'>
                <div className='label'>
                  <label>Lightness</label>
                  <div>{props.settings.lightness}</div>
                </div>
                <Slider
                  min={0}
                  max={100}
                  onChange={(e) => {
                    props.settings.setLightness(Number(e));
                  }}
                  value={props.settings.lightness}
                />
              </div>

              <div className='control-group'>
                <div className='label'>
                  <label>Contrast</label>
                  <div>
                    {props.settings.contrast1} / {props.settings.contrast2}
                  </div>
                </div>
                <Slider
                  min={-100}
                  max={100}
                  onChange={(e) => {
                    props.settings.setContrast1(Number(e));
                  }}
                  startPoint={props.settings.contrast2}
                  value={props.settings.contrast1}
                />
                <Slider
                  min={-100}
                  max={100}
                  onChange={(e) => {
                    props.settings.setContrast2(Number(e));
                  }}
                  startPoint={props.settings.contrast1}
                  value={props.settings.contrast2}
                />
              </div>

              <div className='control-group'>
                <div className='label'>
                  <label>Spin</label>
                  <div>
                    {props.settings.spin1} / {props.settings.spin2}
                  </div>
                </div>
                <Slider
                  min={-180}
                  max={180}
                  onChange={(e) => {
                    props.settings.setSpin1(Number(e));
                  }}
                  startPoint={props.settings.spin2}
                  value={props.settings.spin1}
                />
                <Slider
                  min={-180}
                  max={180}
                  onChange={(e) => {
                    props.settings.setSpin2(Number(e));
                  }}
                  startPoint={props.settings.spin1}
                  value={props.settings.spin2}
                />
              </div>
              <hr />
              <h4>Secondary</h4>
              <div className='control-group'>
                <div className='label'>
                  <label>Offset</label>
                  <div>{props.settings.offsetSecondary}</div>
                </div>
                <Slider
                  min={-180}
                  max={180}
                  startPoint={0}
                  onChange={(e) => {
                    props.settings.setOffsetSecondary(Number(e));
                  }}
                  value={props.settings.offsetSecondary}
                />
              </div>
              <hr />
              <h4>Accent</h4>
              <div className='control-group'>
                <div className='label'>
                  <label>Offset</label>
                  <div>{props.settings.offsetAccent}</div>
                </div>
                <Slider
                  min={-180}
                  max={180}
                  startPoint={0}
                  onChange={(e) => {
                    props.settings.setOffsetAccent(Number(e));
                  }}
                  value={props.settings.offsetAccent}
                />
              </div>
              <hr />
              <h4>Background</h4>
              <div className='control-group'>
                <div className='label'>
                  <label>Offset</label>
                  <div>{props.settings.offsetBackground}</div>
                </div>
                <Slider
                  min={-180}
                  max={180}
                  startPoint={0}
                  onChange={(e) => {
                    props.settings.setOffsetBackground(Number(e));
                  }}
                  value={props.settings.offsetBackground}
                />
              </div>
              <div className='control-group'>
                <div className='label'>
                  <label>Saturation</label>
                  <div>{props.settings.saturationBackground}</div>
                </div>
                <Slider
                  min={0}
                  max={100}
                  startPoint={0}
                  onChange={(e) => {
                    props.settings.setSaturationBackground(Number(e));
                  }}
                  value={props.settings.saturationBackground}
                />
              </div>
              <div className='control-group'>
                <div className='label'>
                  <label>Lightness</label>
                  <div>
                    {props.theme.mode === 'dark'
                      ? props.settings.lightnessBackgroundDark
                      : props.settings.lightnessBackground}
                  </div>
                </div>
                <Slider
                  min={0}
                  max={100}
                  startPoint={0}
                  onChange={(e) => {
                    props.theme.mode === 'dark'
                      ? props.settings.setLightnessBackgroundDark(Number(e))
                      : props.settings.setLightnessBackground(Number(e));
                  }}
                  value={
                    props.theme.mode === 'dark'
                      ? props.settings.lightnessBackgroundDark
                      : props.settings.lightnessBackground
                  }
                />
              </div>
              <div className='control-group'>
                <div className='label'>
                  <label>Contrast</label>
                  <div>
                    {props.settings.contrast1Background} /{' '}
                    {props.settings.contrast2Background}
                  </div>
                </div>
                <Slider
                  min={-100}
                  max={100}
                  onChange={(e) => {
                    props.settings.setContrast1Background(Number(e));
                  }}
                  startPoint={props.settings.contrast2Background}
                  value={props.settings.contrast1Background}
                />
                <Slider
                  min={-100}
                  max={100}
                  onChange={(e) => {
                    props.settings.setContrast2Background(Number(e));
                  }}
                  startPoint={props.settings.contrast1Background}
                  value={props.settings.contrast2Background}
                />
              </div>
            </Controls>
          )}
          <div style={{ marginBottom: props.theme.size[4], display: 'flex' }}>
            <div
              style={{
                width: props.theme.size[48],
                marginRight: props.theme.size[4],
              }}
            >
              <Button
                onClick={() => setShowControls(!showControls)}
                active={showControls}
                square={true}
              >
                <i className='fal fa-2x fa-sliders-h'></i>
              </Button>
              <JSONPretty id='json-pretty' data={exampleColor1}></JSONPretty>
            </div>
            <div>
              <h4>Primary</h4>
              <Palette
                color={[props.theme.colors[props.theme.mode].primary[50]]}
                count={21}
                domain={[0, 100]}
                contrast={[props.settings.contrast1, props.settings.contrast2]}
                spin={[props.settings.spin1, props.settings.spin2]}
                height={props.theme.size[16]}
                showNumbers={true}
                contrastColors={[
                  props.theme.colors[props.theme.mode].foreground[100],
                  props.theme.colors[props.theme.mode].foregroundContrast[100],
                ]}
              />
              <h4>Secondary</h4>
              <Palette
                color={[props.theme.colors[props.theme.mode].secondary[50]]}
                count={21}
                domain={[0, 100]}
                contrast={[props.settings.contrast1, props.settings.contrast2]}
                spin={[props.settings.spin1, props.settings.spin2]}
                height={props.theme.size[16]}
                showNumbers={true}
                contrastColors={[
                  props.theme.colors[props.theme.mode].foreground[100],
                  props.theme.colors[props.theme.mode].foregroundContrast[100],
                ]}
              />
              <h4>Accent</h4>
              <Palette
                color={[props.theme.colors[props.theme.mode].accent[50]]}
                count={21}
                domain={[0, 100]}
                contrast={[props.settings.contrast1, props.settings.contrast2]}
                spin={[props.settings.spin1, props.settings.spin2]}
                height={props.theme.size[16]}
                showNumbers={true}
                contrastColors={[
                  props.theme.colors[props.theme.mode].foreground[100],
                  props.theme.colors[props.theme.mode].foregroundContrast[100],
                ]}
              />
              <h4>Background</h4>
              <Palette
                color={[props.theme.colors[props.theme.mode].background[50]]}
                count={21}
                domain={[0, 100]}
                contrast={[
                  props.settings.contrast1Background,
                  props.settings.contrast2Background,
                ]}
                spin={[props.settings.spin1, props.settings.spin2]}
                height={props.theme.size[16]}
                showNumbers={true}
                contrastColors={[
                  props.theme.colors[props.theme.mode].foreground[100],
                  props.theme.colors[props.theme.mode].foregroundContrast[100],
                ]}
              />
            </div>
          </div>
          <div style={{ marginBottom: props.theme.size[4], display: 'flex' }}>
            <div
              style={{
                width: props.theme.size[48],
                marginRight: props.theme.size[4],
              }}
            >
              <JSONPretty id='json-pretty' data={exampleColor2}></JSONPretty>
            </div>

            <Palette
              color={exampleColor2.color}
              count={exampleColor2.count}
              domain={exampleColor2.domain}
              contrast={exampleColor2.contrast}
              spin={exampleColor2.spin}
              height={props.theme.size[32]}
              showNumbers={true}
              contrastColors={[
                props.theme.colors[props.theme.mode].foreground[100],
                props.theme.colors[props.theme.mode].foregroundContrast[100],
              ]}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                width: props.theme.size[48],
                marginRight: props.theme.size[4],
              }}
            >
              <JSONPretty id='json-pretty' data={exampleColor3}></JSONPretty>
            </div>

            <Palette
              color={exampleColor3.color}
              count={exampleColor3.count}
              domain={exampleColor3.domain}
              height={props.theme.size[32]}
              showNumbers={true}
              contrastColors={[
                props.theme.colors[props.theme.mode].foreground[100],
                props.theme.colors[props.theme.mode].foregroundContrast[100],
              ]}
            />
          </div>
        </Typography>
      </div>
    </HomeScreen>
  );
}
