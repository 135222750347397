export const createSize = (
  size = 8,
  count = 65,
  domain = [0, 64],
  format = 'px'
) => {
  if (isNaN(size) || size > 256 || size < 0) {
    return 'Size must be a number between 1 and 256';
  }
  if (isNaN(count) || count > 1000 || count < 1) {
    return 'Count must be a number between 1 and 1000';
  }
  if (isNaN(domain[0]) || isNaN(domain[1])) {
    return 'Domain must be two numbers';
  }
  const keyIncrement = (domain[1] - domain[0]) / (count - 1);

  let scale = {};
  for (let i = 0; i < count; i++) {
    const sizeName = Math.floor(domain[0] + keyIncrement * i);
    if (format === 'px') {
      scale[sizeName] = `${i * size}px`;
    } else if (format === 'em') {
      scale[sizeName] = `${i * size}em`;
    } else if (format === 'rem') {
      scale[sizeName] = `${i * size}rem`;
    } else if (format === 'vw') {
      scale[sizeName] = `${i * size}vw`;
    } else {
      scale[sizeName] = i * size;
    }
  }
  return scale;
};
