import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { getMiddleColor } from '../../../theme/utils/getMiddleColor';
import { getContrast } from '../../../theme/utils/getContrast';
import Palette from '../Palette/Palette';
import Button from '../../Button/Button';
import Confirmation from '../../Confirmation/Confirmation';

const ColorList = (props) => {
  const colors = props.colors;
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingColor, setDeletingColor] = useState({});

  const handleToggleDeleting = (color) => {
    setIsDeleting(true);
    setDeletingColor(color);
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    props.handleReorderColors(result);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'white',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : 'white',
  });
  return (
    <div data-testid='ColorList'>
      <div style={{ fontWeight: '600' }}>Colors</div>
      {colors && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable'>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {Object.keys(colors).map((item, index) => (
                  <>
                    <Draggable
                      key={props.colors[item].id}
                      draggableId={props.colors[item].id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '4px',
                                background: getMiddleColor(
                                  props.colors[item].value
                                ),
                                color: getContrast(
                                  getMiddleColor(props.colors[item].value),
                                  ['#ffffff', '#000000']
                                ),
                              }}
                            >
                              {index} - {props.colors[item].name} -{' '}
                              {getMiddleColor(props.colors[item].value)}
                              <div>
                                <Button
                                  onClick={() =>
                                    handleToggleDeleting(props.colors[item])
                                  }
                                  size='small'
                                  square={true}
                                >
                                  <i className='fal fa-trash'></i>
                                </Button>
                              </div>
                            </div>
                            <Palette
                              color={props.colors[item].value}
                              count={props.colors[item].count}
                              domain={props.colors[item].domain}
                              spin={props.colors[item].spin}
                              contrast={props.colors[item].contrast}
                              format={props.colors[item].format}
                              height={props.theme.size[6]}
                            />
                          </div>
                        </div>
                      )}
                    </Draggable>
                    {isDeleting &&
                      deletingColor.id == props.colors[item].id && (
                        <Confirmation
                          isShowing={isDeleting}
                          setIsShowing={setIsDeleting}
                          handleAction={() => {
                            props.handleRemoveColor(
                              props.colors[item].id
                            );
                          }}
                          message='Are you sure you want to delete this
                    Color?'
                          buttonTextYes='Yes, Delete Color'
                          buttonTextNo='No, Keep Color'
                        />
                      )}
                  </>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
};

ColorList.propTypes = {};

ColorList.defaultProps = {};

export default ColorList;
