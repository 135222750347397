import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

let TypeTestStyles = styled.div((props) => ({
  padding: props.theme.size[3]
}));

let TypeTestSection = styled.div((props) => ({
  marginBottom: props.theme.size[6],
}));

const TypeTest = (props) => {
  return (
<TypeTestStyles theme={props.theme} data-testid='TypeTest'>
      <h2>Typography</h2>
      {Object.keys(props.sizes).map((key2) => (
        <TypeTestSection
          key={key2}
          theme={props.theme}
        >
          <div
            theme={props.theme}
            style={{
              fontSize: props.sizes[key2],
              lineHeight: props.heights[key2],
              marginBottom: props.heights[key2],
            }}
          >
            Size: {key2}<br />
            Font Size: {props.sizes[key2]}<br />
            Line_height: {props.heights[key2]}
          </div>
          <div>
            <p style={{
              fontSize: props.sizes[key2],
              lineHeight: props.heights[key2],
            }}>
              Integer urna ante, varius sit amet orci id, hendrerit molestie est.
              Praesent posuere metus ac sapien tempus, finibus posuere mi pellentesque.
              Maecenas consequat lacus sed ipsum laoreet pharetra.Proin cursus pharetra
              imperdiet.Aliquam eget sem nisi.Sed ac massa urna.Cras vel sem in orci
              ultricies volutpat sed vel dui.Praesent gravida turpis id tellus mollis
              mollis et eu erat.Phasellus id magna at est finibus dignissim.
            </p>
          </div>
        </TypeTestSection>
      ))}
    </TypeTestStyles>
  );
};

TypeTest.propTypes = {
  sizes: PropTypes.object,
  heights: PropTypes.object,
  showNumbers: PropTypes.bool,
  theme: PropTypes.object,
};

TypeTest.defaultProps = {
  sizes: {},
  heights: {},
  showNumbers: false,
  theme: {},
};

export default TypeTest;
