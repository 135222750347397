import React, { useState } from 'react';
import styled from '@emotion/styled';
import JSONPretty from 'react-json-pretty';
import Slider from 'rc-slider';

import Typography from '../Typography/Typography';
import Button from '../Button/Button';
import { HomeScreen } from '../../pages/Home/Home';
import { Columns, Column2, Controls } from './Styles';

export const RulesDepthStyles = styled.div((props) => ({
  '.size-chart-container': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '.size-chart': {
    flex: `0 0 ${props.theme.size[24]}`,
    display: 'flex',
    flexWrap: 'wrap',
  },
  '.size-chart-item': {
    background: props.theme.colors[props.theme.mode].primary[50],
    fontSize: props.theme.fontSize[2],
    lineHeight: props.theme.lineHeight[2],
  },
  '.size-chart-content': {
    padding: `${props.theme.size[1]} ${props.theme.size[0]}`,
    textAlign: 'center',
    border: `1px solid ${props.theme.colors[props.theme.mode].background[0]}`,
  },
  '.size-chart-legend': {
    background: props.theme.colors[props.theme.mode].background[0],
  },
  '.size-chart-label': {
    width: props.theme.size[24],
    padding: `${props.theme.size[1]} ${props.theme.size[0]}`,
    textAlign: 'center',
    border: `1px solid ${props.theme.colors[props.theme.mode].background[0]}`,
    fontSize: props.theme.fontSize[2],
    lineHeight: props.theme.lineHeight[2],
  },

  '.size-chart-border': {
    flex: `0 0 ${props.theme.size[48]}`,
  },
  '.size-chart-border-container': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '.size-chart-border-item': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: props.theme.size[2],
  },
  '.size-chart-border-label': {
    width: props.theme.size[24],
    background: props.theme.colors[props.theme.mode].background[0],
    textAlign: 'center',
    fontSize: props.theme.fontSize[2],
    lineHeight: props.theme.lineHeight[2],
    padding: `${props.theme.size[1]} ${props.theme.size[2]}`,
  },
  '.size-chart-border-item-border': {
    flex: '0 0 50%',
    background: props.theme.colors[props.theme.mode].background[100],
  },
}));
export function RulesDepth(props) {
  const [showControls, setShowControls] = useState(false);

  const borderExample = {
    size: props.settings.border,
    count: 17,
    domain: [0, 16],
    format: 'px',
  };

  const borderDemo = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <HomeScreen theme={props.theme}>
      <RulesDepthStyles theme={props.theme} className='container'>
        <Typography>
          <i
            className='fal fa-2x fa-ruler-combined'
            style={{ marginBottom: props.theme.size[2] }}
          ></i>
          <h2>Depths</h2>
          <Columns theme={props.theme}>
            <Column2 theme={props.theme}>
              <h3>Rules</h3>
              <ul></ul>
            </Column2>
            <Column2 theme={props.theme}>
              <h3>Constraints</h3>
              <ul></ul>
            </Column2>
          </Columns>
          {showControls && (
            <Controls theme={props.theme}>
              <div className='controls-header'>Size</div>
              <div className='controls-actions'>
                <Button onClick={() => setShowControls(false)} square={true}>
                  <i className='fal fa-close'></i>
                </Button>
              </div>
              <div className='control-group'>
                <div className='label'>
                  <label>Space</label>
                  <div>{props.settings.size}</div>
                </div>
                <Slider
                  min={1}
                  max={16}
                  onChange={(e) => {
                    props.settings.setSize(Number(e));
                  }}
                  value={props.settings.size}
                />
              </div>
            </Controls>
          )}

          <div>
            <div className='size-chart-border-container'>
              <div style={{ padding: props.theme.size[4] }}>
                <JSONPretty id='json-pretty' data={borderExample}></JSONPretty>
              </div>
              <div
                style={{ padding: props.theme.size[4] }}
                className='size-chart-border'
              >
                {
                  // loop through fonborderDemotDemo
                  borderDemo.map((border, key) => (
                    <div key={key} className='size-chart-border-item'>
                      <div className='size-chart-border-label'>
                        Depth: {border}
                        {props.theme.depth[border]}
                      </div>
                      <div
                        className='size-chart-border-item-border'
                        style={{
                          boxShadow: props.theme.depth[border],
                          height: props.theme.size[12],
                        }}
                      ></div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </Typography>
      </RulesDepthStyles>
    </HomeScreen>
  );
}
