
export const randomName = () => {
    // genearet a random name
    let text = '';
    const possible =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 5; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
};

export const randomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const randomContrast = () => {
    return [randomNumber(-40, -2), randomNumber(2, 40)];
};
export const randomSpin = () => {
    return [randomNumber(-60, 60), randomNumber(-60, 60)];
};