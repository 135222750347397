import React, { useState } from 'react';
import styled from '@emotion/styled';
import JSONPretty from 'react-json-pretty';
import Slider from 'rc-slider';

import Typography from '../Typography/Typography';
import Button from '../Button/Button';
import { HomeScreen } from '../../pages/Home/Home';
import { Columns, Column2, Controls } from './Styles';

import { getContrast } from '../../theme/utils/getContrast';

export const RulesFontStyles = styled.div((props) => ({
  '.size-chart-container': {
    display: 'flex',
  },
  '.size-chart-item': {
    display: 'flex',
    marginBottom: props.theme.size[2],
  },
  '.size-chart-label': {
    marginRight: props.theme.size[2],
    width: props.theme.size[18],
    textAlign: 'right',
    color: getContrast(props.theme.colors[props.theme.mode].background[50], [
      props.theme.colors[props.theme.mode].primary[0],
      props.theme.colors[props.theme.mode].primary[100],
    ]),
    span: {
      fontSize: props.theme.fontSize[-2],
      lineHeight: props.theme.lineHeight[1],
      fontWeight: 700,
      padding: `0 ${props.theme.size[1]}`,
      borderRadius: props.theme.size[1],
      background: props.theme.colors[props.theme.mode].accent[50],
      color: getContrast(props.theme.colors[props.theme.mode].accent[50], [
        props.theme.colors[props.theme.mode].foreground[100],
        props.theme.colors[props.theme.mode].foregroundContrast[100],
      ]),
      marginRight: props.theme.size[1],
      textTransform: 'uppercase',
    },
  },
}));
export function RulesFont(props) {
  const [showControls, setShowControls] = useState(false);

  const fontDemo = [-2, -1, 0, 1, 2, 3, 4, 8, 12, 24];
  const fontExample = {
    value: props.settings.fontSize,
    count: 27,
    domain: [-2, 24],
    round: 2,
    increment: 0.125,
  };
  const lineHeightExample = {
    value: props.settings.lineHeight,
    count: 27,
    domain: [-2, 24],
    round: 16,
    increment: 0.125,
    multiplyer: 1.25,
  };
  return (
    <HomeScreen theme={props.theme}>
      <RulesFontStyles theme={props.theme} className='container'>
        <Typography>
          <i
            className='fal fa-2x fa-text-height'
            style={{ marginBottom: props.theme.size[2] }}
          ></i>
          <h2>Font Size</h2>
          <Columns theme={props.theme}>
            <Column2 theme={props.theme}>
              <h3>Rules</h3>
              <ul>
                <li>Font size base is 16px</li>
                <li>
                  Increment font-size and line-height by size * (size *
                  increment)
                </li>
                <li>Smallest font size is 14px</li>
                <li>Round font-size values to 2px</li>
                <li>Lineheight uses 1.5x multiplier</li>
                <li>Round line-height values to 16px</li>
              </ul>
            </Column2>
            <Column2 theme={props.theme}>
              <h3>Constraints</h3>
              <ul>
                <li>Pixel values are converted to rem</li>
                <li>Size values are between [-2, 64]</li>
              </ul>
            </Column2>
          </Columns>
          {showControls && (
            <Controls theme={props.theme}>
              <div className='controls-header'>Font</div>
              <div className='controls-actions'>
                <Button
                  onClick={(e) => props.settings.resetTheme()}
                  square={true}
                >
                  <i className='fal fa-sync'></i>
                </Button>
                <Button onClick={() => setShowControls(false)} square={true}>
                  <i className='fal fa-close'></i>
                </Button>
              </div>
              <div className='control-group'>
                <div className='label'>
                  <label>Fonts</label>
                  <div>{props.settings.fontSize}</div>
                </div>
                <Slider
                  min={1}
                  max={24}
                  onChange={(e) => {
                    props.settings.setFontSize(Number(e));
                  }}
                  value={props.settings.fontSize}
                />
              </div>
              <div className='control-group'>
                <div className='label'>
                  <label>Line Height</label>
                  <div>{props.settings.lineHeight}</div>
                </div>
                <Slider
                  min={1}
                  max={24}
                  onChange={(e) => {
                    props.settings.setLineHeight(Number(e));
                  }}
                  value={props.settings.lineHeight}
                />

                <Button
                  onClick={() =>
                    props.settings.setLineHeightSync(
                      !props.settings.lineHeightSync
                    )
                  }
                  active={props.settings.lineHeightSync}
                  square={true}
                >
                  {props.settings.lineHeightSync ? (
                    <i className='fal fa-lock'></i>
                  ) : (
                    <i className='fal fa-unlock'></i>
                  )}
                </Button>
              </div>
            </Controls>
          )}
          <div className='size-chart-container'>
            <div style={{ padding: props.theme.size[4] }}>
              <div style={{ paddingBottom: props.theme.size[4] }}>
                <Button
                  onClick={() => setShowControls(!showControls)}
                  active={showControls}
                  square={true}
                >
                  <i className='fal fa-2x fa-sliders-h'></i>
                </Button>
                <h4>Font Size:</h4>
                <JSONPretty id='json-pretty' data={fontExample}></JSONPretty>
              </div>
              <h4>Line Height:</h4>
              <JSONPretty
                id='json-pretty'
                data={lineHeightExample}
              ></JSONPretty>
            </div>
            <div style={{ padding: props.theme.size[4] }}>
              {
                // loop through fontDemo
                fontDemo.map((font, key) => (
                  <div
                    key={key}
                    className='size-chart-item'
                    style={{
                      fontSize: props.theme.fontSize[font],
                      lineHeight: props.theme.lineHeight[font],
                    }}
                  >
                    <div className='size-chart-label'>
                      {font === 0 && <span>Base</span>}
                      {font}
                    </div>
                    <div>
                      {props.theme.fontSize[font]}
                      <br />
                      {props.theme.lineHeight[font]}
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </Typography>
      </RulesFontStyles>
    </HomeScreen>
  );
}
