import React, { useState, useEffect } from 'react';
import useLocalStorage from 'use-local-storage';

import { createDepth } from '../theme/utils/createDepth';
import { themeDefault } from '../defaults/theme.default';

const defaultState = {};

const ThemeContext = React.createContext(defaultState);

const ThemeProvider = ({ url, children }) => {
  // SET LOCAL STORAGE
  // Modes
  const [modeLocal, setModeLocal] = useLocalStorage('mode', themeDefault.mode);
  const [modeSnycLocal, setModeSnycLocal] = useLocalStorage(
    'modeSync',
    themeDefault.modeSync
  );
  const [modeAutoLocal, setModeAutoLocal] = useLocalStorage(
    'modeAuto',
    themeDefault.modeAuto
  );

  // SET STATE
  // Modes
  const [modes, setModes] = useState(themeDefault.modes);
  const [modeSync, setModeSync] = useState(
    validateLocalStorage() ? modeSnycLocal : themeDefault.modeSync
  );
  const [modePreferred, setModePreferred] = useState(themeDefault.modePrefered);
  const [modeAuto, setModeAuto] = useState(
    validateLocalStorage() ? modeAutoLocal : themeDefault.modeAuto
  );
  const [mode, setMode] = useState(
    validateLocalStorage() ? modeLocal : themeDefault.mode
  );
  // Colors
  const [colors, setColors] = useState(themeDefault.colors);
  // Sizes
  const [size, setSize] = useState(themeDefault.size);
  const [sizeBase, setSizeBase] = useState(themeDefault.sizeBase);
  const [sizeUnit, setSizeUnit] = useState(themeDefault.sizeUnit);
  const [border, setBorder] = useState(themeDefault.borders);
  const [radius, setRadius] = useState(themeDefault.radius);
  const [depth, setDepth] = useState(themeDefault.depth);
  const [fontSize, setFontSize] = useState(themeDefault.fontSize);
  const [lineHeight, setLineHeight] = useState(themeDefault.lineHeight);

  const setModeManually = (mode) => {
    setMode(mode);
    setModeAuto(false);
  };

  useEffect(() => {
    setDepth(
      createDepth(2, 9, [0, 8], colors[mode].background[0], 0.6, 0.2, 0, 2)
    );
  }, [mode, colors]);

  // modes
  useEffect(() => {
    setModeSnycLocal(modeSync);
  }, [setModeSnycLocal, modeSync]);

  useEffect(() => {
    setModeAutoLocal(modeAuto);
  }, [setModeAutoLocal, modeAuto]);

  useEffect(() => {
    setModeLocal(mode);
  }, [setModeLocal, mode]);

  useEffect(() => {
    if (modeAuto) {
      setMode(modePreferred === 'light' ? 'light' : 'dark');
    }
  }, [modePreferred, modeAuto]);

  useEffect(() => {
    // Add listener to update styles
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e) => {
        setModePreferred(e.matches ? 'dark' : 'light');
      });

    // Setup dark/light mode for the first time
    setModePreferred(
      window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light'
    );

    // Remove listener
    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', () => {});
    };
  }, []);

  function validateLocalStorage() {
    // check if modes does not contain modeLocal
    if (modes.indexOf(modeLocal) === -1) {
      return false;
    }
    return true;
  }

  return (
    <ThemeContext.Provider
      value={{
        modes,
        setModes,
        mode,
        setModeSync,
        modeSync,
        setMode,
        colors,
        setColors,
        size,
        setSize,
        border,
        setBorder,
        radius,
        setRadius,
        depth,
        setDepth,
        fontSize,
        setFontSize,
        lineHeight,
        setLineHeight,
        modeAuto,
        setModeAuto,
        modePreferred,
        setModeManually,
        sizeBase,
        setSizeBase,
        sizeUnit,
        setSizeUnit,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;

export { ThemeProvider };
