import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ThemeContext from '../../../context/ThemeContext';

import { createColorScale } from '../../../theme/utils/createColorScale';
import { getContrast } from '../../../theme/utils/getContrast';
import { getContrastRatio } from '../../../theme/utils/getContrast';

const Palette = (props) => {
  const [colors, setColors] = useState([]);

  // Set update loop
  useEffect(() => {
    setColors(
      createColorScale(
        props.color,
        props.count,
        props.domain,
        props.contrast,
        props.spin,
        props.format
      )
    );
  }, [
    props.color,
    props.count,
    props.domain,
    props.contrast,
    props.spin,
    props.format,
  ]);

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <div data-testid='Palette' style={{ width: '100%' }}>
          <div style={{ width: '100%', display: 'flex', textAlign: 'center' }}>
            {Object.keys(colors).map((key2) => (
              <div
                key={key2}
                style={{
                  flex: '1 1 auto',
                  height: `${props.height}`,
                  padding: '4px',
                  background: colors[key2],
                  color: getContrast(colors[key2], [
                    props.contrastColors[0],
                    props.contrastColors[1],
                  ]),
                }}
              >
                {props.showNumbers && (
                  <div>
                    {key2}
                    <div style={{ padding: theme.size[1] }}>
                      {getContrastRatio(
                        colors[key2],
                        getContrast(colors[key2], [
                          props.contrastColors[0],
                          props.contrastColors[1],
                        ])
                      ).pass ? (
                        <i className='fas fa-check-circle'></i>
                      ) : (
                        <i className='fas fa-exclamation-circle'></i>
                      )}
                    </div>
                    <div style={{ fontWeight: 700 }}>
                      {
                        getContrastRatio(
                          colors[key2],
                          getContrast(colors[key2], [
                            props.contrastColors[0],
                            props.contrastColors[1],
                          ])
                        ).ratio
                      }
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </ThemeContext.Consumer>
  );
};

Palette.propTypes = {
  color: PropTypes.array,
  count: PropTypes.number,
  domain: PropTypes.array,
  contrast: PropTypes.array,
  spin: PropTypes.array,
  format: PropTypes.string,
  showNumbers: PropTypes.bool,
  height: PropTypes.string,
  contrastColors: PropTypes.array,
};

Palette.defaultProps = {
  color: [],
  showNumbers: false,
  height: 10,
  contrastColors: ['#ffffff', '#000000'],
};

export default Palette;
