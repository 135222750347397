import { getContrast } from '../../theme/utils/getContrast';
import chroma from 'chroma-js';

export function setFieldBaseStyles(props) {
  return {
    cursor: 'pointer',
    border: 0,
    borderRadius: props.theme.radius[1],
    display: 'flex-inline',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid',
    div: {
      marginTop: props.theme.size[1],
    },
  };
}

export function setFieldSize(props) {
  const calcVertPadding = (s, b = 0, lh = 0) => {
    let finalSize;
    finalSize = `calc((${props.theme.size[s]} / 2) - ${props.theme.border[b]} - (${props.theme.lineHeight[lh]} / 2))`;
    return finalSize;
  };
  const calcHorzPadding = (s, b = 0) => {
    let finalSize;
    finalSize = `calc((${props.theme.size[s]} / 2) - ${props.theme.border[b]})`;
    return finalSize;
  };

  let FieldSize = {};

  switch (props.size) {
    case 'small':
      FieldSize = {
        fontSize: props.theme.fontSize[-3],
        lineHeight: props.theme.lineHeight[-3],
        padding: `${calcVertPadding(3, 1, -3)} ${calcHorzPadding(3, 1)}`,
        i: {
          marginRight: '0.5rem',
        },
      };
      break;
    case 'medium':
      FieldSize = {
        fontSize: props.theme.fontSize[0],
        lineHeight: props.theme.lineHeight[0],
        padding: `${calcVertPadding(4, 1, 0)} ${calcHorzPadding(4, 1)}`,
        i: {
          marginRight: '0.5rem',
        },
      };
      break;
    case 'large':
      FieldSize = {
        fontSize: props.theme.fontSize[2],
        lineHeight: props.theme.lineHeight[2],
        padding: `${calcVertPadding(6, 1, 2)} ${calcHorzPadding(6, 1)}`,
        i: {
          marginRight: '0.5rem',
        },
      };
      break;
    case 'huge':
      FieldSize = {
        fontSize: props.theme.fontSize[4],
        lineHeight: props.theme.lineHeight[4],
        padding: `${calcVertPadding(10, 1, 4)} ${calcHorzPadding(10, 1)}`,
        i: {
          marginRight: '0.5rem',
        },
      };
      break;
  }
  return FieldSize;
}

export function setFieldColors(props) {
  let FieldColors = {
    base: {},
    active: {},
    hover: {},
  };

  switch (props.color) {
    case 'danger':
      FieldColors.base = {
        backgroundColor: chroma(
          props.theme.colors[props.theme.mode].foreground[0]
        )
          .alpha(0.2)
          .css(),
        color: props.theme.colors[props.theme.mode].primary[100],
        borderColor: props.theme.colors[props.theme.mode].primary[100],
      };
      FieldColors.active = {
        backgroundColor: chroma(
          props.theme.colors[props.theme.mode].foreground[0]
        )
          .alpha(0.2)
          .css(),
        color: props.theme.colors[props.theme.mode].primary[100],
        borderColor: props.theme.colors[props.theme.mode].primary[100],
      };
      FieldColors.hover = {
        backgroundColor: chroma(
          props.theme.colors[props.theme.mode].foreground[0]
        )
          .alpha(0.2)
          .css(),
        color: props.theme.colors[props.theme.mode].primary[100],
        borderColor: props.theme.colors[props.theme.mode].primary[100],
      };
      break;
    default:
      FieldColors.base = {
        backgroundColor: chroma(
          props.theme.colors[props.theme.mode].foreground[0]
        )
          .alpha(0.2)
          .css(),
        color: props.theme.colors[props.theme.mode].foreground[100],
        borderColor: props.theme.colors[props.theme.mode].foreground[0],
      };
      FieldColors.active = {
        backgroundColor: chroma(
          props.theme.colors[props.theme.mode].foreground[0]
        )
          .alpha(0.4)
          .css(),
        color: props.theme.colors[props.theme.mode].foreground[100],
        borderColor: props.theme.colors[props.theme.mode].foreground[0],
      };
      FieldColors.hover = {
        backgroundColor: chroma(
          props.theme.colors[props.theme.mode].foreground[0]
        )
          .alpha(0.15)
          .css(),
        color: props.theme.colors[props.theme.mode].foreground[100],
        borderColor: props.theme.colors[props.theme.mode].foreground[0],
      };
      break;
  }

  return FieldColors;
}
