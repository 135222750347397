import React from 'react';
import PropTypes from 'prop-types';

import { getContrast } from '../../../theme/utils/getContrast';
import { getContrastRatio } from '../../../theme/utils/getContrast';

const Palette = (props) => {
  return (
    <div data-testid='Palette'>
      <div
        style={{
          width: '100%',
          display: 'flex',
          marginBottom: props.theme.size[8],
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 auto',
            textAlign: 'center',
          }}
        >
          {props.showNumbers && (
            <div
              style={{
                padding: '4px',
                fontSize: props.theme.fontSize[3],
                lineHeight: props.theme.lineHeight[3],
                fontWeight: 700,
                height: props.theme.size[4],
              }}
            >
              <h3>{props.colorName}</h3>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '4px',
              height: `${props.height}`,
            }}
          >
            {props.colorName === 'foregroundContrast'
              ? 'foreground[100]'
              : 'background[100]'}
          </div>
          <div
            style={{
              display: 'flex',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '4px',
              height: `${props.height}`,
            }}
          >
            {props.colorName === 'foregroundContrast'
              ? 'primary{50]'
              : 'background[0]'}
          </div>
        </div>
        {Object.keys(props.colors).map((key2) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: '1 1 auto',
              textAlign: 'center',
            }}
            key={key2}
          >
            {props.showNumbers && (
              <div
                style={{
                  padding: '4px',
                  fontSize: props.theme.fontSize[3],
                  lineHeight: props.theme.lineHeight[3],
                  fontWeight: 700,
                  height: props.theme.size[4],
                }}
              >
                {key2}
              </div>
            )}
            <div
              style={{
                display: 'flex',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '4px',
                height: `${props.height}`,
                background:
                  props.colorName === 'foregroundContrast'
                    ? props.theme.colors[props.theme.mode].foreground[100]
                    : props.theme.colors[props.theme.mode].background[100],
                color: props.colors[key2],
              }}
            >
              <div style={{ padding: props.theme.size[1] }}>
                {getContrastRatio(
                  props.colors[key2],
                  props.colorName === 'foregroundContrast'
                    ? props.theme.colors[props.theme.mode].foreground[100]
                    : props.theme.colors[props.theme.mode].background[100]
                ).pass ? (
                  <i className='fal fa-check-circle'></i>
                ) : (
                  <i className='fas fa-exclamation-circle'></i>
                )}
              </div>
              <div style={{ fontWeight: 700 }}>
                {
                  getContrastRatio(
                    props.colors[key2],
                    props.colorName === 'foregroundContrast'
                      ? props.theme.colors[props.theme.mode].foreground[100]
                      : props.theme.colors[props.theme.mode].background[100]
                  ).ratio
                }
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '4px',
                height: `${props.height}`,
                background:
                  props.colorName === 'foregroundContrast'
                    ? props.theme.colors[props.theme.mode].primary[50]
                    : props.theme.colors[props.theme.mode].background[0],
                color: props.colors[key2],
              }}
            >
              <div style={{ padding: props.theme.size[1] }}>
                {getContrastRatio(
                  props.colors[key2],
                  props.colorName === 'foregroundContrast'
                    ? props.theme.colors[props.theme.mode].primary[50]
                    : props.theme.colors[props.theme.mode].background[0]
                ).pass ? (
                  <i className='fal fa-check-circle'></i>
                ) : (
                  <i className='fas fa-exclamation-circle'></i>
                )}
              </div>
              <div style={{ fontWeight: 700 }}>
                {
                  getContrastRatio(
                    props.colors[key2],
                    props.colorName === 'foregroundContrast'
                      ? props.theme.colors[props.theme.mode].primary[50]
                      : props.theme.colors[props.theme.mode].background[0]
                  ).ratio
                }
              </div>
            </div>
            <div
              key={key2}
              style={{
                flex: '1 1 auto',
                height: `${props.height}`,
                display: 'flex',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '4px',
                background: props.colors[key2],
                color: getContrast(props.colors[key2], ['#ffffff', '#000000']),
              }}
            >
              <div style={{ padding: props.theme.size[1] }}>
                {getContrastRatio(
                  props.colors[key2],
                  getContrast(props.colors[key2], [
                    props.theme.colors[props.theme.mode].foreground[100],
                    props.theme.colors[props.theme.mode].primary[100],
                  ])
                ).pass ? (
                  <i className='fal fa-check-circle'></i>
                ) : (
                  <i className='fas fa-exclamation-circle'></i>
                )}
              </div>
              <div style={{ fontWeight: 700 }}>
                {
                  getContrastRatio(
                    props.colors[key2],
                    getContrast(props.colors[key2], [
                      props.theme.colors[props.theme.mode].foreground[100],
                      props.theme.colors[props.theme.mode].primary[100],
                    ])
                  ).ratio
                }
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

Palette.propTypes = {
  colors: PropTypes.object,
  showNumbers: PropTypes.bool,
  height: PropTypes.string,
};

Palette.defaultProps = {
  colors: {},
  showNumbers: false,
  height: 'auto',
};

export default Palette;
