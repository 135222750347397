import styled from '@emotion/styled';
import { getContrast } from '../../theme/utils/getContrast';

export const SlideCounter = styled.div((props) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  padding: [props.theme.size[4]],
  color: props.theme.colors[props.theme.mode].foreground[0],
  opacity: 0.5,
}));
export const Columns = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: [props.theme.size[4]],
}));
export const Column2 = styled.div((props) => ({
  flex: `0 0 calc(100% / 2 - ${props.theme.size[4]} * 2)`,
  padding: [props.theme.size[4]],
}));
export const Column3 = styled.div((props) => ({
  flex: `0 0 calc(100% / 3 - ${props.theme.size[4]} * 2)`,
  padding: props.theme.size[4],
}));

export const Controls = styled.div((props) => ({
  background: props.theme.colors[props.theme.mode].background[0],
  position: 'fixed',
  zIndex: 2000,
  bottom: 0,
  right: 0,
  padding: `${props.theme.size[6]} ${props.theme.size[2]} 0`,
  width: '30%',
  '.control-group': {
    marginBottom: props.theme.size[2],
  },
  '.label': {
    display: 'flex',
  },
  label: {
    marginRight: props.theme.size[2],
    fontWeight: 600,
    color: getContrast(props.theme.colors[props.theme.mode].background[0], [
      props.theme.colors[props.theme.mode].primary[0],
      props.theme.colors[props.theme.mode].primary[50],
    ]),
  },
  '.controls-header': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    background: props.theme.colors[props.theme.mode].background[100],
    paddingLeft: props.theme.size[2],
    fontSize: props.theme.fontSize[0],
    lineHeight: props.theme.size[3],
    color: getContrast(props.theme.colors[props.theme.mode].background[100], [
      props.theme.colors[props.theme.mode].foreground[100],
      props.theme.colors[props.theme.mode].foregroundContrast[100],
    ]),
  },
  '.controls-actions': {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  // RC Slider
  '.rc-slider': {
    padding: `${props.theme.size[1]} 0`,
  },
  '.rc-slider-rail': {
    backgroundColor: props.theme.colors[props.theme.mode].background[100],
  },
  '.rc-slider-track': {
    backgroundColor: getContrast(
      props.theme.colors[props.theme.mode].background[0],
      [
        props.theme.colors[props.theme.mode].primary[0],
        props.theme.colors[props.theme.mode].primary[100],
      ]
    ),
  },
  '.rc-slider-handle': {
    backgroundColor: props.theme.colors[props.theme.mode].background[100],
    borderColor: props.theme.colors[props.theme.mode].foreground[100],
  },
}));
