import chroma from 'chroma-js';
import React, { useState, useEffect } from 'react';
import useLocalStorage from 'use-local-storage';
import { v4 as uuidv4 } from 'uuid';

const defaultState = {};

const ColorsContext = React.createContext(defaultState);

const ColorsProvider = ({ url, children }) => {
  const getNewColorId = () => {
    return uuidv4();
  };

  // set DEFAULT THEME
  const modesDefault = ['light', 'dark'];
  const modeDefault = 'light';
  const valuesDefault = {
    light: [
      {
        id: getNewColorId(),
        name: 'Primary',
        value: ['#4965f5'],
        count: 21,
        domain: [0, 100],
        contrast: [-32, 19],
        spin: [29, -11],
        format: 'hex',
      },
    ],
    dark: [
      {
        id: getNewColorId(),
        name: 'Primary',
        value: ['#ba09b8'],
        count: 21,
        domain: [0, 100],
        contrast: [-13, 36],
        spin: [-20, 20],
        format: 'hex',
      },
    ],
  };

  // set LOCALSTORAGE
  const [modesLocal, setModesLocal] = useLocalStorage('modes', modesDefault);
  const [modeLocal, setModeLocal] = useLocalStorage('mode', modeDefault);
  const [valueslocal, setValuesLocal] = useLocalStorage(
    'values',
    valuesDefault
  );

  // set STATE
  const [modes, setModes] = useState(
    validateLocalStorage() ? modesLocal : modesDefault
  );
  const [mode, setMode] = useState(
    validateLocalStorage() ? modeLocal : modeDefault
  );
  const [values, setValues] = useState(
    validateLocalStorage() ? valueslocal : valuesDefault
  );

  function validateLocalStorage() {
    return true;
  }

  useEffect(() => {
    setModesLocal(modes);
  }, [setModesLocal, modes]);

  useEffect(() => {
    setModeLocal(mode);
  }, [setModeLocal, mode]);

  useEffect(() => {
    setValuesLocal(values);
  }, [setValuesLocal, values]);

  const resetColors = () => {
    setModes(modesDefault);
    setMode(modeDefault);
    setValues(valuesDefault);
  };

  const setTheme = (values, modes, mode) => {
    setValues(values);
    setModes(modes);
    setMode(mode);
  };

  const addMode = (value) => {
    const tempModes = [...modes, value];
    setModes(tempModes);
    // add mode to colors
    addNewColor(
      value,
      'Primary',
      [chroma.random().hex()],
      21,
      [0, 100],
      [-20, 20],
      [0, 0],
      'hex'
    );
  };

  const removeMode = (value) => {
    if (value === mode) {
      if (modes.length === 1) {
        // dont remove current mode
        return false;
      } else if (modes.indexOf(value) === modes.length - 1) {
        // set to previous mode after value
        setMode(modes[modes.indexOf(value) - 1]);
      } else {
        // set to next mode after value
        setMode(modes[modes.indexOf(value) + 1]);
      }
    }
    const tempModes = modes.filter((m) => m !== value);
    setModes(tempModes);
    // remove mode from values
    const tempValues = { ...values };
    delete tempValues[value];
    setValues(tempValues);
  };

  const removeColor = (id) => {
    const tempColors = values[mode];
    tempColors.splice(
      tempColors.findIndex((color) => color.id === id),
      1
    );
    setValues({
      ...values,
      [mode]: tempColors,
    });
  };

  const reorderColors = (result) => {
    // reoder colors
    const tempColors = values[mode];
    const tempColor = tempColors.find(
      (color) => color.id === result.draggableId
    );
    // remove color
    tempColors.splice(result.source.index, 1);
    // add color at new position
    tempColors.splice(result.destination.index, 0, tempColor);
    // setColors
    setValues({
      ...values,
      [mode]: tempColors,
    });
  };

  const addNewColor = (
    mode,
    name,
    value,
    count = 20,
    range = [0, 100],
    contrast = [-20, 20],
    spin = [0, 0],
    format = 'hex'
  ) => {
    setValues({
      ...values,
      [mode]: [
        ...(values[mode] ? values[mode] : []),
        {
          id: getNewColorId(),
          name: name,
          value: value,
          count: count,
          domain: [range[0], range[1]],
          contrast: [contrast[0], contrast[1]],
          spin: [spin[0], spin[1]],
          format: format,
        },
      ],
    });
  };

  return (
    <ColorsContext.Provider
      value={{
        resetColors,
        values,
        setValues,
        removeColor,
        reorderColors,
        addMode,
        removeMode,
        addNewColor,
        modes,
        mode,
        setModes,
        setMode,
        setTheme,
      }}
    >
      {children}
    </ColorsContext.Provider>
  );
};

export default ColorsContext;

export { ColorsProvider };
