import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Typography from '../../../components/Typography/Typography';
import Button from '../../../components/Button/Button';

const SaveProjectStyles = styled.div((props) => ({
  padding: props.theme.size[4],
}));

const SaveProject = (props) => {
  const [name, setName] = useState('Untitled');

  // Set update loop
  useEffect(() => {}, []);

  const handleToggleSaving = (toggle) => {
    props.setIsSaving(toggle);
  };

  const handleSaveProject = () => {
    props.project.saveProject({
      name: name,
      version: 0,
      data: {
        values: props.colors.values,
        modes: props.colors.modes,
        mode: props.colors.mode,
      },
    });
    props.setIsSaving(false);
  };

  return (
    <Typography>
      <SaveProjectStyles data-testid='SaveProject' theme={props.theme}>
        <div>Save As:</div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSaveProject(e.target.elements.name.value);
          }}
        >
          <label htmlFor='color'>Project Name: </label>
          <input
            name='name'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Button size='small' onClick={() => handleToggleSaving(false)}>
            Cancel
          </Button>
          <Button size='small'>Save Project</Button>
        </form>
      </SaveProjectStyles>
    </Typography>
  );
};

SaveProject.propTypes = {};

SaveProject.defaultProps = {};

export default SaveProject;
