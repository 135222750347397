export const createFontFamilt = () => {
  return {
    display: "'Karla', sans-serif",
    body: "'Karla', sans-serif",
    mono: 'Courier, monospace',
  };
};

export const createFontSize = (
  value = 16,
  count = 28,
  domain = [-3, 24],
  round = 2,
  increment = 0.125
) => {
  const sizeincrement = (domain[1] - domain[0]) / (count - 1);
  const scale = {};
  for (let i = 0; i < count; i++) {
    const sizeName = Math.floor(domain[0] + sizeincrement * i);
    const size = scaleFont(sizeName, value, round, increment);
    //const sizePx = size + 'px';
    const sizeRem = (1 / 16) * size + 'rem';
    scale[sizeName] = sizeRem;
  }
  return scale;
};

export const scaleFont = (size, baseValue, round, increment) => {
  const scaledValue = baseValue + increments(size, increment);
  const roundedValue = round * Math.round(scaledValue / round);
  return roundedValue;
};

export const createLineHeight = (
  value = 16,
  count = 28,
  domain = [-3, 24],
  round = 2,
  increment = 0.125,
  multiplyer = 1.5
) => {
  const sizeincrement = (domain[1] - domain[0]) / (count - 1);
  const scale = {};
  for (let i = 0; i < count; i++) {
    const sizeName = Math.floor(domain[0] + sizeincrement * i);
    const height = scaleLineHeight(
      sizeName,
      value,
      round,
      increment,
      multiplyer
    );
    //const heightPx = height + 'px';
    const heightRem = (1 / 16) * height + 'rem';
    scale[sizeName] = heightRem;
  }

  return scale;
};

export const scaleLineHeight = (
  size,
  baseValue,
  round,
  increment,
  multiplyer
) => {
  const scaledValue = baseValue * multiplyer + increments(size, increment);
  const roundedValue = round * Math.round(scaledValue / round);
  return roundedValue;
};

export const increments = (size = 0, increment = 0.125) => {
  const exponent = size * increment * size;
  const value = size + exponent;
  return value;
};
