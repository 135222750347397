import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import JSONPretty from 'react-json-pretty';
import styled from '@emotion/styled';
import { useAuth0 } from '@auth0/auth0-react';

import Projects from '../pages/Projects/Projects';

import ThemeContext from '../context/ThemeContext';

import AddProject from '../components/Project/AddProject/AddProject';
import SaveProject from '../components/Project/SaveProject/SaveProject';

import Colors from '../components/Colors/Colors/Colors';
import ThemeOutput from '../components/ThemeOutput/ThemeOutput';
import ColorCanvas from '../components/Colors/ColorCanvas/ColorCanvas';
import CanvasList from '../components/Colors/CanvasList/CanvasList';
import ColorsContext from '../context/ColorsContext';

import Button from '../components/Button/Button';
import LinkButton from '../components/Button/LinkButton';

import LoginMessage from '../components/LoginMessage/LoginMessage';
import Typography from '../components/Typography/Typography';
import { ProjectsListener } from '../listener/Projects.Listener';
import ProjectsContext from '../context/ProjectsContext';
import Confirmation from '../components/Confirmation/Confirmation';

const WorkspaceStyles = styled.div((props) => ({
  paddingTop: props.theme.size[8],
}));

const Sidebar = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: props.theme.size[1],
  gap: props.theme.size[1],
}));
const Menubar = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: props.theme.size[1],
  gap: props.theme.size[1],
}));

export function Workspace(props) {
  const { isAuthenticated } = useAuth0();
  const [isResetting, setIsRestting] = React.useState(false);

  const [isAdding, setIsAdding] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);

  return isAuthenticated ? (
    <ThemeContext.Consumer>
      {(theme) => (
        <ProjectsContext.Consumer>
          {(project) => (
            <ColorsContext.Consumer>
              {(colors) => (
                <Router>
                  <ProjectsListener project={project} colors={colors}>
                    <WorkspaceStyles
                      className='app-container'
                      theme={props.theme}
                    >
                      <div
                        className='Header'
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Menubar theme={props.theme}>
                          <Button
                            onClick={() => {
                              setIsRestting(true);
                            }}
                          >
                            Reset Workspace
                          </Button>
                          {isResetting && (
                            <Confirmation
                              isShowing={isResetting}
                              setIsShowing={setIsRestting}
                              handleAction={() => {
                                colors.resetColors();
                              }}
                              message='Are you sure you want to reset the workspace?'
                              buttonTextYes='Yes, Reset Workspace'
                              buttonTextNo='No, Keep Working'
                            />
                          )}
                          <Button
                            onClick={() => {
                              setIsAdding(!isAdding);
                              setIsSaving(false);
                            }}
                          >
                            <i className='fal fa-file'></i>New Project
                          </Button>
                          {isAdding && (
                            <AddProject
                              theme={theme}
                              colors={colors}
                              setIsAdding={setIsAdding}
                              project={project}
                            />
                          )}

                          {project.project && (
                            <Button
                              onClick={() => {
                                project.updateProject({
                                  project_id: project.project.project_id,
                                  name: project.project.name,
                                  version: project.project.version,
                                  data: {
                                    values: colors.values,
                                    modes: colors.modes,
                                    mode: colors.mode,
                                  },
                                });
                              }}
                            >
                              <i className='fal fa-save'></i>
                              Save
                            </Button>
                          )}
                          <Button
                            onClick={() => {
                              setIsAdding(false);
                              setIsSaving(!isSaving);
                            }}
                          >
                            <i className='fal fa-save'></i>
                            Save As
                          </Button>
                          {isSaving && (
                            <SaveProject
                              theme={theme}
                              colors={colors}
                              setIsSaving={setIsSaving}
                              project={project}
                            />
                          )}
                        </Menubar>
                        <h1
                          style={{
                            fontSize: 18,
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          Super Mega Theme Machine 500
                          {project.project && project.project.name && (
                            <>
                              <div>{project.project.name}</div>
                              <Button
                                onClick={() => {
                                  project.getProject(null);
                                }}
                              >
                                <i className='fal fa-times'></i>
                                Close Project
                              </Button>
                            </>
                          )}
                        </h1>
                      </div>
                      <Sidebar theme={props.theme}>
                        <LinkButton to='/app' size='huge' square={true}>
                          <i className='fas fa-swatchbook'></i>
                          <div>Theme</div>
                        </LinkButton>
                        <LinkButton
                          to='/app/projects'
                          size='huge'
                          square={true}
                        >
                          <i className='fas fa-folders'></i>
                          <div>Projects</div>
                        </LinkButton>
                        <LinkButton to='/app/colors' size='huge' square={true}>
                          <i className='fas fa-palette'></i>
                          <div>Colors</div>
                        </LinkButton>
                      </Sidebar>
                      <Switch>
                        <Route path='/app' exact>
                          App
                        </Route>
                        <Route path='/app/colors'>
                          <div className='Colors'>
                            <CanvasList theme={props.theme} colors={colors} />
                            <ColorCanvas colors={colors} theme={props.theme} />
                            <ThemeOutput colors={colors}></ThemeOutput>
                            <br />
                            <br />
                            <Typography>
                              <h4>Theme Configuration</h4>
                              <JSONPretty
                                id='json-pretty'
                                data={colors.values}
                              ></JSONPretty>
                            </Typography>
                          </div>
                          <div className='Toolbar'>
                            <Colors theme={props.theme} colors={colors} />
                          </div>
                        </Route>
                        <Route path='/app/projects'>
                          <Projects />
                        </Route>
                      </Switch>
                    </WorkspaceStyles>
                  </ProjectsListener>
                  {JSON.stringify(project.project)}
                </Router>
              )}
            </ColorsContext.Consumer>
          )}
        </ProjectsContext.Consumer>
      )}
    </ThemeContext.Consumer>
  ) : (
    <LoginMessage>Please Login to use app</LoginMessage>
  );
}
