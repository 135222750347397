import React from 'react';
import 'react-json-pretty/themes/monikai.css';
import PropTypes from 'prop-types';
import { createColorScale } from '../../theme/utils/createColorScale';

import Typography from '../Typography/Typography';

const ThemeOutput = (props) => {
  const themeOutput = {};

  if (props.colors) {
    Object.keys(props.colors.values).forEach((mode) => {
      Object.keys(props.colors.values[mode]).forEach((color) => {
        // push to themeOutput[mode]
        themeOutput[mode] = themeOutput[mode] || {};
        themeOutput[mode][props.colors.values[mode][color].name] =
          createColorScale(
            props.colors.values[mode][color].value,
            props.colors.values[mode][color].count,
            props.colors.values[mode][color].domain,
            props.colors.values[mode][color].contrast,
            props.colors.values[mode][color].spin,
            props.colors.values[mode][color].format
          );
      });
    });
  }

  return (
    <Typography data-testid='ThemeOutput'>
      <h4>Theme Output</h4>
      <div>{JSON.stringify(themeOutput)}</div>
    </Typography>
  );
};

ThemeOutput.propTypes = {
  colors: PropTypes.object.isRequired,
};

ThemeOutput.defaultProps = {
  colors: {},
};

export default ThemeOutput;
