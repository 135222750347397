import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Typography from '../../../components/Typography/Typography';
import Button from '../../../components/Button/Button';

const EditProjectStyles = styled.div((props) => ({
  padding: props.theme.size[4],
}));

const EditProject = (props) => {
  const [name, setName] = useState(props.editingProject.name);
  const project_id = props.editingProject.project_id;
  const version = props.editingProject.version;
  const data = props.editingProject.data;

  // Set update loop
  useEffect(() => {}, []);

  const handleToggleEditing = (toggle) => {
    props.setIsEditing(toggle);
  };

  const handleEditProject = () => {
    props.project.updateProject({
      name: name,
      version: version,
      project_id: project_id,
      data: data,
    });
    props.setIsEditing(false);
  };

  return (
    <Typography>
      <EditProjectStyles data-testid='EditProject' theme={props.theme}>
        <div>Edit:</div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleEditProject(e.target.elements.name.value);
          }}
        >
          <label htmlFor='color'>Project Name: </label>
          <input
            name='name'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Button size='small' onClick={() => handleToggleEditing(false)}>
            Cancel
          </Button>
          <Button size='small'>Save Changes</Button>
        </form>
      </EditProjectStyles>
    </Typography>
  );
};

EditProject.propTypes = {};

EditProject.defaultProps = {};

export default EditProject;
