var chroma = require('chroma-js');

export const createColorScale = (
  color,
  count = 21,
  domain = [0, 100],
  contrast = [-20, 20],
  spin = [0, 0],
  format = 'hex'
) => {
  if (!color || color.length === 0) {
    return 'Color must be one or more valid color property';
  }
  if (isNaN(count) || count > 1000 || count < 1) {
    return 'Count must be a number between 1 and 1000';
  }
  if (isNaN(domain[0]) || isNaN(domain[1])) {
    return 'Domain must be two numbers';
  }
  if (isNaN(contrast[0]) || isNaN(contrast[1])) {
    return 'Contrast must be two numbers';
  }
  if (isNaN(spin[0]) || isNaN(spin[1])) {
    return 'Spin must be two numbers';
  }
  if (!format) {
    return 'Format is a required string';
  }

  const increment = (domain[1] - domain[0]) / (count - 1);

  let scale = {};
  let colorvar = '';

  if (color.length > 1) {
    colorvar = chroma.scale(color).domain([domain[0], domain[1]]);
  } else {
    const colorLightness = chroma(color[0]).get('hsl.l');
    const colorHue = chroma(color[0]).get('hsl.h');
    const colorDark = chroma(color[0])
      .set('hsl.l', colorLightness + contrast[0] * 0.01)
      .set('hsl.h', colorHue + spin[0])
      .hex();
    const colorLight = chroma(color[0])
      .set('hsl.l', colorLightness + contrast[1] * 0.01)
      .set('hsl.h', colorHue + spin[1])
      .hex();

    colorvar = chroma
      .scale([colorDark, color[0], colorLight])
      .domain([domain[0], domain[1]]);
  }

  for (let i = 0; i < count; i++) {
    const colorName = Math.floor(domain[0] + increment * i);
    if (format === 'hex') {
      scale[colorName] = colorvar(colorName).hex();
    } else {
      scale[colorName] = colorvar(colorName).css();
    }
  }

  return scale;
};
