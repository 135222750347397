import React, { useEffect } from 'react';
import styled from '@emotion/styled';

import Slide from '../../components/Typography/Slide/Slide';

import { RulesColor } from '../../components/Home/RulesColor';
import { RulesDepth } from '../../components/Home/RulesDepth';
import { RulesSize } from '../../components/Home/RulesSize';
import { RulesFont } from '../../components/Home/RulesFont';
import { WhatIs } from '../../components/Home/WhatIs';

const HomeStyles = styled.div((props) => ({
  // styles go here
}));

export const HomeScreen = styled.div((props) => ({
  position: 'relative',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  borderBottom: `${props.theme.border[1]} solid ${
    props.theme.colors[props.theme.mode].background[100]
  }`,
  '.container': {
    padding: [props.theme.size[10]],
  },
  '.center': {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
}));

const Home = (props) => {
  // Set update loop
  useEffect(() => {}, []);

  return (
    <HomeStyles data-testid='Home'>
      <HomeScreen theme={props.theme}>
        <div className='container center'>
          <Slide>
            <h2>Creating &amp; Implementing</h2>
            <h1>Design Systems</h1>
            <p>With Jesse Stewart</p>
            <p
              style={{
                color: props.theme.colors[props.theme.mode].primary[50],
              }}
            >
              http://www.JesseDidThis.com
            </p>
          </Slide>
        </div>
      </HomeScreen>
      <WhatIs theme={props.theme}></WhatIs>
      <HomeScreen theme={props.theme}>
        <div className='container center'>
          <Slide>
            <h2>Rules &amp; Constraints</h2>
            <p>Design Tokens are based on a set of Rules &amp; Constraints</p>
          </Slide>
        </div>
      </HomeScreen>
      <RulesDepth settings={props.settings} theme={props.theme}></RulesDepth>
      <RulesSize settings={props.settings} theme={props.theme}></RulesSize>
      <RulesFont settings={props.settings} theme={props.theme}></RulesFont>
      <RulesColor settings={props.settings} theme={props.theme}></RulesColor>
      <HomeScreen settings={props.settings} theme={props.theme}>
        <div className='container center'>
          <Slide>
            <h2>Tools and Configurators</h2>
            <p>Create tools that encourage exploration and experimentation.</p>
          </Slide>
        </div>
      </HomeScreen>
      <HomeScreen theme={props.theme}>
        <div className='container center'>
          <Slide>
            <h2>Key Takeaways</h2>
            <ul>
              <li>Your Design System should make you faster.</li>
              <li>Set rules and constraints for your Design Tokens.</li>
              <li>Use configurations to generate Design Tokens.</li>
              <li>Think outside the box of what a Design System can be.</li>
              <li>
                Create tools for designers to configure and experiment with
                Design Tokens.
              </li>
              <li>The best Design System is the one that you use.</li>
            </ul>
          </Slide>
        </div>
      </HomeScreen>
    </HomeStyles>
  );
};

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
