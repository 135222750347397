import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import ThemeContext from '../../context/ThemeContext';
import Button from '../Button/Button';
import chroma from 'chroma-js';

const ConfirmationStyles = styled.div((props) => ({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  background: chroma(props.theme.colors[props.theme.mode].background[0])
    .alpha(0.6)
    .css(),
  '.confirm-window': {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    background: props.theme.colors[props.theme.mode].background[50],
  },
  '.close-button': {
    position: 'absolute',
    top: '0',
    right: '0',
  },
  '.confirm-message': {
    padding: props.theme.size[4],
    fontSize: props.theme.fontSize[0],
    lineHeight: props.theme.lineHeight[0],
  },
  '.confirm-title': {
    marginBottom: props.theme.size[2],
    fontWeight: '600',
    fontSize: props.theme.fontSize[2],
    lineHeight: props.theme.lineHeight[2],
  },
  '.confirm-actions': {
    padding: props.theme.size[2],
    background: props.theme.colors[props.theme.mode].background[35],
    display: 'flex',
    justifyContent: 'flex-end',
    Button: {
      marginLeft: props.theme.size[2],
      '&:first-of-type': {
        marginLeft: props.theme.size[8],
      },
    },
  },
}));

const Confirmation = (props) => {
  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <ConfirmationStyles
          data-testid='Confirmation'
          theme={theme}
          onClick={() => {
            props.setIsShowing(false);
          }}
        >
          <div className='confirm-window'>
            <div className='close-button'>
              <Button
                onClick={() => {
                  props.setIsShowing(false);
                }}
                square={true}
              >
                <i className='fas fa-times'></i>
              </Button>
            </div>
            <div className='confirm-message'>
              <div className='confirm-title'>Are You Sure?</div>
              <p>{props.message}</p>
            </div>
            <div className='confirm-actions'>
              <Button
                onClick={() => {
                  props.setIsShowing(false);
                }}
              >
                {props.buttonTextNo}
              </Button>
              <Button
                color='danger'
                onClick={() => {
                  props.setIsShowing(false);
                  props.handleAction();
                }}
              >
                {props.buttonTextYes}
              </Button>
            </div>
          </div>
        </ConfirmationStyles>
      )}
    </ThemeContext.Consumer>
  );
};

Confirmation.propTypes = {
  isShowing: PropTypes.bool,
  setIsShowing: PropTypes.func,
  handleAction: PropTypes.func,
  message: PropTypes.string,
  buttonTextYes: PropTypes.string,
  buttonTextNo: PropTypes.string,
};

Confirmation.defaultProps = {
  isShowing: false,
  setIsShowing: () => {},
  handleAction: () => {},
  message: 'Are you sure?',
  buttonTextYes: 'Yes',
  buttonTextNo: 'No',
};

export default Confirmation;
