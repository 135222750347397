import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import chroma from 'chroma-js';
import Slider from 'rc-slider';
import { HexColorPicker } from 'react-colorful';
import 'rc-slider/assets/index.css';

import {
  randomName,
  randomContrast,
  randomSpin,
} from '../../../theme/utils/random';

import Palette from '../Palette/Palette';
import Button from '../../Button/Button';
import Confirmation from '../../Confirmation/Confirmation';
import ButtonSet from '../../Button/ButtonSet';
import InputField from '../../Forms/InputField';
import FieldSet from '../../Forms/FieldSet';

const AddSwatch = (props) => {
  const [name, setName] = useState();
  const [values, setValues] = useState(['#ff0000']);
  const [color, setColor] = useState('#ff0000');
  const [colorText, setColorText] = useState('#ff0000');
  const [count, setCount] = useState(21);
  const [domainLow, setDomainLow] = useState(0);
  const [domainHigh, setDomainHigh] = useState(100);
  const [contrastLow, setContrastLow] = useState(-20);
  const [contrastHigh, setContrastHigh] = useState(20);
  const [spinLow, setSpinLow] = useState(-20);
  const [spinHigh, setSpinHigh] = useState(20);

  const [formCount, setFormCount] = useState(21);
  const [formDomainLow, setFormDomainLow] = useState(0);
  const [formDomainHigh, setFormDomainHigh] = useState(100);
  const [formContrastLow, setFormContrastLow] = useState(-20);
  const [formContrastHigh, setFormContrastHigh] = useState(20);
  const [formSpinLow, setFormSpinLow] = useState(-20);
  const [formSpinHigh, setFormSpinHigh] = useState(20);

  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editing, setEditing] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleting, setDeleting] = useState();

  const handleSaveSwatch = () => {
    props.colors.addNewColor(
      props.colors.mode,
      name ? name : randomName(),
      values,
      count,
      [domainLow, domainHigh],
      [contrastLow, contrastHigh],
      [spinLow, spinHigh]
    );
    props.setIsSwatchAdding(false);
  };

  const handleCancelSwatch = () => {
    props.setIsSwatchAdding(false);
  };

  const handleAddNewColor1 = () => {
    props.colors.addNewColor(
      props.colors.mode,
      randomName(),
      [chroma.random().hex()],
      count,
      [domainLow, domainHigh],
      randomContrast(),
      randomSpin(),
      'hex'
    );
  };

  const handleAddNewColor2 = () => {
    props.colors.addNewColor(
      props.colors.mode,
      randomName(),
      [chroma.random().hex(), chroma.random().hex(), chroma.random().hex()],
      count,
      [domainLow, domainHigh],
      randomContrast(),
      randomSpin(),
      'hex'
    );
  };

  const handleToggleAdding = (toggle) => {
    setIsAdding(toggle);
    setIsEditing(false);
  };

  const handleToggleEditColor = (toggle, index = 0) => {
    setIsEditing(toggle);
    setIsAdding(false);
    setEditing(index);
    setColor(values[index]);
    setColorText(values[index]);
  };

  const handleAddColor = () => {
    setValues([...values, color]);
    setIsAdding(false);
  };

  const handleRemoveColor = (index) => {
    setIsEditing(false);
    setIsDeleting(true);
    setDeleting(index);
  };

  const handleEditColor = (index, value) => {
    setValues(values.map((_, i) => (i === index ? value : _)));
    setIsEditing(false);
  };

  const getEditingValues = () => {
    // replace values[editing] with color
    return values.map((_, i) => (i === editing ? color : _));
  };

  const getAddingValues = () => {
    // add color to end of values
    return [...values, color];
  };

  const validateColorText = (colorText) => {
    setColorText(colorText);
    if (chroma.valid(colorText)) {
      setColor(colorText);
    }
  };

  const validateColorPicker = (colorText) => {
    setColor(colorText);
    if (chroma.valid(colorText)) {
      setColorText(colorText);
    }
  };

  const validateCount = (value) => {
    // verify that value is a number more than 0
    if (Number(value) > 0) {
      setCount(Number(value));
    } else {
      setCount(20);
    }
  };

  const validateDomainLow = (value) => {
    // verify that value is a number
    if (Number(value)) {
      setDomainLow(Number(value));
    } else {
      setDomainLow([0]);
    }
  };

  const validateDomainHigh = (value) => {
    // verify that value is a number
    if (Number(value)) {
      setDomainHigh(Number(value));
    } else {
      setDomainHigh([100]);
    }
  };

  const isValuesGradient = () => {
    // check if more than one color in values
    if (values.length > 1) {
      return true;
    }
    if (isAdding) {
      return true;
    }
    return false;
  };

  const validateContrastLow = (value) => {
    // verify that value is a number
    if (Number(value) || value === 0) {
      setContrastLow(Number(value));
    } else {
      setContrastLow([-20]);
    }
  };

  const validateContrastHigh = (value) => {
    // verify that value is a number
    if (Number(value) || value === 0) {
      setContrastHigh(Number(value));
    } else {
      setContrastHigh([20]);
    }
  };

  const validateSpinLow = (value) => {
    // verify that value is a number
    if (Number(value) || value === 0) {
      setSpinLow(Number(value));
    } else {
      setSpinLow([-20]);
    }
  };

  const validateSpinHigh = (value) => {
    // verify that value is a number
    if (Number(value) || value === 0) {
      setSpinHigh(Number(value));
    } else {
      setSpinHigh([20]);
    }
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    // find in values
    const newValues = [...values];
    const [removed] = newValues.splice(result.source.index, 1);
    newValues.splice(result.destination.index, 0, removed);
    setValues(newValues);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'white',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : 'white',
  });

  return (
    props.isSwatchAdding && (
      <div
        style={{
          padding: props.theme.size[2],
          background: [props.theme.colors[props.theme.mode].background[20]],
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: props.theme.size[1],
          }}
        >
          <h4>Add Palette</h4>
          <ButtonSet theme={props.theme}>
            <Button
              onClick={() => {
                handleAddNewColor1();
              }}
              size='small'
              square={true}
            >
              <i className='fal fa-random'></i>
            </Button>
            <Button
              onClick={() => {
                handleAddNewColor2();
              }}
              size='small'
              square={true}
            >
              <i className='fal fa-blender'></i>
            </Button>
            <Button
              onClick={() => {
                handleCancelSwatch();
              }}
              size='small'
              square={true}
            >
              <i className='fal fa-close'></i>
            </Button>
          </ButtonSet>
        </div>
        <div data-testid='ColorArray'>
          {isEditing && (
            <div
              style={{
                padding: props.theme.size[2],
                background: [
                  props.theme.colors[props.theme.mode].background[0],
                ],
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: props.theme.size[1],
                }}
              >
                <h4>Edit Color</h4>
                <Button
                  size='small'
                  square={true}
                  onClick={() => handleToggleEditColor(false)}
                >
                  <i className='fal fa-close'></i>
                </Button>
              </div>
              <div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleEditColor(editing, colorText);
                  }}
                >
                  <label htmlFor='color'>Color: </label>
                  <input
                    name='color'
                    value={colorText}
                    onChange={(e) => validateColorText(e.target.value)}
                  />
                  <HexColorPicker
                    color={chroma(color ? color : '#ffffff').hex()}
                    onChange={(color) => validateColorPicker(color)}
                  />
                  <Button
                    size='small'
                    onClick={() => handleToggleEditColor(false)}
                  >
                    Cancel
                  </Button>
                  <Button size='small'>Save Color</Button>
                </form>
              </div>
            </div>
          )}

          {isAdding && (
            <div
              style={{
                padding: props.theme.size[2],
                background: [
                  props.theme.colors[props.theme.mode].background[0],
                ],
                margin: `0 -${props.theme.size[2]} ${props.theme.size[2]}`,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: props.theme.size[1],
                }}
              >
                <h4>Add Swatch</h4>
                <Button
                  onClick={() => {
                    handleToggleAdding(false);
                  }}
                  size='small'
                  square={true}
                >
                  <i className='fal fa-close'></i>
                </Button>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleAddColor(e.target.elements.color.value);
                }}
              >
                <label htmlFor='color'>Swatch: </label>
                <input
                  name='color'
                  value={colorText}
                  onChange={(e) => validateColorText(e.target.value)}
                />
                <HexColorPicker
                  color={chroma(color ? color : '#ffffff').hex()}
                  onChange={(color) => validateColorPicker(color)}
                />
                <Button size='small' onClick={() => handleToggleAdding(false)}>
                  Cancel
                </Button>
                <Button size='small'>Add Swatch</Button>
              </form>
            </div>
          )}

          <div>
            {values && (
              <div>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='droppable' direction='horizontal'>
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={
                          (getListStyle(snapshot.isDraggingOver),
                          { display: 'flex', justifyContent: 'center' })
                        }
                      >
                        {values.map((value, index) => (
                          <Draggable
                            key={index}
                            draggableId={value + index}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <div
                                  style={{
                                    background:
                                      props.theme.colors[props.theme.mode]
                                        .background[0],
                                  }}
                                >
                                  <Button
                                    onClick={() =>
                                      handleToggleEditColor(!isEditing, index)
                                    }
                                    active={isEditing && editing === index}
                                    size='small'
                                    square={true}
                                  >
                                    <i className='fal fa-edit'></i>
                                  </Button>
                                  <Button
                                    onClick={() => handleRemoveColor(index)}
                                    size='small'
                                    square={true}
                                  >
                                    <i className='fal fa-trash'></i>
                                  </Button>
                                  {isDeleting && deleting == index && (
                                    <Confirmation
                                      isShowing={isDeleting}
                                      setIsShowing={setIsDeleting}
                                      handleAction={() => {
                                        setValues(
                                          values.filter((_, i) => i !== index)
                                        );
                                      }}
                                      message='Are you sure you want to delete this Swatch
                                ?'
                                      buttonTextYes='Yes, Delete Swatch'
                                      buttonTextNo='No, Keep Swatch'
                                    />
                                  )}
                                  <div
                                    style={{
                                      padding: '10px',
                                      backgroundColor:
                                        isEditing && editing === index
                                          ? color
                                          : value,
                                    }}
                                  >
                                    {isEditing && editing === index && (
                                      <div>
                                        <i className='fal fa-pencil'></i>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                        <div>
                          <Button
                            onClick={() => {
                              handleToggleAdding(!isAdding);
                            }}
                            active={isAdding}
                            size='small'
                            square={true}
                            color='primary'
                          >
                            <i className='fal fa-add'></i>
                          </Button>
                        </div>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

                {
                  // if values is empty, then show this
                  values.length === 0 && <p>No swatches added</p>
                }
              </div>
            )}
          </div>
        </div>
        {values.length !== 0 && count > 0 && (
          <Palette
            color={
              isEditing
                ? getEditingValues()
                : isAdding
                ? getAddingValues()
                : values
            }
            count={count}
            contrast={[contrastLow, contrastHigh]}
            spin={[spinLow, spinHigh]}
            height={props.theme.size[12]}
          ></Palette>
        )}

        <h4>Swatch Details</h4>

        <InputField size='small'>
          <label htmlFor='name'>Name:</label>
          <input
            id='name'
            type='text'
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </InputField>

        <InputField size='small'>
          <label htmlFor='count'>Count:</label>
          <input
            id='count'
            type='text'
            value={formCount}
            onChange={(e) => {
              setFormCount(e.target.value);
            }}
            onBlur={() => {
              validateCount(formCount);
            }}
            style={{ width: props.theme.size[4] }}
          />
        </InputField>

        <InputField size='small'>
          <label for='domain'>Domain:</label>
          <FieldSet>
            <input
              id='domain'
              type='text'
              value={formDomainLow}
              onChange={(e) => {
                setFormDomainLow(e.target.value);
              }}
              onBlur={() => {
                validateDomainLow(formDomainLow);
              }}
              style={{ width: props.theme.size[4] }}
            />
            <input
              id='domain2'
              type='text'
              value={formDomainHigh}
              onChange={(e) => {
                setFormDomainHigh(e.target.value);
              }}
              onBlur={() => {
                validateDomainHigh(formDomainHigh);
              }}
              style={{ width: props.theme.size[4] }}
            />
          </FieldSet>
        </InputField>
        {!isValuesGradient() && (
          <>
            <InputField size='small' direction='column'>
              <label htmlFor='name'>Contrast:</label>
              <FieldSet style={{ width: '100%' }}>
                <input
                  id='name'
                  type='text'
                  value={formContrastLow}
                  onChange={(e) => {
                    setFormContrastLow(e.target.value);
                  }}
                  onBlur={() => {
                    validateContrastLow(formContrastLow);
                  }}
                  style={{ width: props.theme.size[4] }}
                  disabled={isValuesGradient()}
                />
                <div style={{ flexGrow: 1 }}>
                  <Slider
                    min={-60}
                    max={60}
                    onChange={(e) => {
                      validateContrastLow(
                        e.target ? Number(e.target.value) : Number(e)
                      );
                      setFormContrastLow(
                        e.target ? Number(e.target.value) : Number(e)
                      );
                    }}
                    startPoint={contrastHigh}
                    value={contrastLow}
                    disabled={isValuesGradient()}
                  />
                  <Slider
                    min={-60}
                    max={60}
                    onChange={(e) => {
                      validateContrastHigh(
                        e.target ? Number(e.target.value) : Number(e)
                      );
                      setFormContrastHigh(
                        e.target ? Number(e.target.value) : Number(e)
                      );
                    }}
                    startPoint={contrastLow}
                    value={contrastHigh}
                    disabled={isValuesGradient()}
                  />
                </div>
                <input
                  id='name'
                  type='text'
                  value={formContrastHigh}
                  onChange={(e) => {
                    setFormContrastHigh(e.target.value);
                  }}
                  onBlur={() => {
                    validateContrastHigh(formContrastHigh);
                  }}
                  style={{ width: props.theme.size[4] }}
                  disabled={isValuesGradient()}
                />
              </FieldSet>
            </InputField>
            <InputField size='small' direction='column'>
              <label htmlFor='name'>Spin:</label>
              <FieldSet style={{ width: '100%' }}>
                <input
                  id='name'
                  type='text'
                  value={formSpinLow}
                  onChange={(e) => {
                    setFormSpinLow(e.target.value);
                  }}
                  onBlur={() => {
                    validateSpinLow(formSpinLow);
                  }}
                  style={{ width: props.theme.size[4] }}
                  disabled={isValuesGradient()}
                />
                <div style={{ flexGrow: 1 }}>
                  <Slider
                    min={-90}
                    max={90}
                    onChange={(e) => {
                      validateSpinLow(
                        e.target ? Number(e.target.value) : Number(e)
                      );
                      setFormSpinLow(
                        e.target ? Number(e.target.value) : Number(e)
                      );
                    }}
                    startPoint={spinHigh}
                    value={spinLow}
                    disabled={isValuesGradient()}
                  />
                  <Slider
                    min={-90}
                    max={90}
                    onChange={(e) => {
                      validateSpinHigh(
                        e.target ? Number(e.target.value) : Number(e)
                      );
                      setFormSpinHigh(
                        e.target ? Number(e.target.value) : Number(e)
                      );
                    }}
                    startPoint={spinLow}
                    value={spinHigh}
                    disabled={isValuesGradient()}
                  />
                </div>
                <input
                  id='name'
                  type='text'
                  value={formSpinHigh}
                  onChange={(e) => {
                    setFormSpinHigh(e.target.value);
                  }}
                  onBlur={() => {
                    validateSpinHigh(formSpinHigh);
                  }}
                  style={{ width: props.theme.size[4] }}
                  disabled={isValuesGradient()}
                />
              </FieldSet>
            </InputField>
          </>
        )}

        <ButtonSet theme={props.theme}>
          <Button
            onClick={() => {
              handleSaveSwatch();
            }}
            color='primary'
          >
            Save Palette
          </Button>
          <Button
            onClick={() => {
              handleCancelSwatch();
            }}
          >
            Cancel
          </Button>
        </ButtonSet>
      </div>
    )
  );
};

AddSwatch.propTypes = {};

AddSwatch.defaultProps = {};

export default AddSwatch;
