import React, { useState, useEffect } from 'react';
import useLocalStorage from 'use-local-storage';

import { defaultSettings } from '../defaults/settings.default';

const defaultState = {};

const SettingsContext = React.createContext(defaultState);

const SettingsProvider = ({ url, children }) => {
  // SET LOCAL STORAGE
  // Settings
  const [sizeLocal, setSizeLocal] = useLocalStorage(
    'size',
    defaultSettings.Size
  );
  const [borderLocal, setBorderLocal] = useLocalStorage(
    'borderLocal',
    defaultSettings.Border
  );
  const [radiusLocal, setRadiusLocal] = useLocalStorage(
    'radiusLocal',
    defaultSettings.Radius
  );
  const [fontSizeLocal, setFontSizeLocal] = useLocalStorage(
    'fontSizeLocal',
    defaultSettings.FontSize
  );
  const [lineHeightLocal, setLineHeightLocal] = useLocalStorage(
    'lineHeightLocal',
    defaultSettings.LineHeight
  );
  const [lineHeightSyncLocal, setLineHeightSyncLocal] = useLocalStorage(
    'lineHeightSyncLocal',
    defaultSettings.LineHeightSync
  );

  const [hueLocal, setHueLocal] = useLocalStorage(
    'hueLocal',
    defaultSettings.Hue
  );
  const [saturationLocal, setSaturationLocal] = useLocalStorage(
    'saturationLocal',
    defaultSettings.Saturation
  );
  const [lightnessLocal, setLightnessLocal] = useLocalStorage(
    'lightnessLocal',
    defaultSettings.Lightness
  );
  const [contrast1Local, setContrast1Local] = useLocalStorage(
    'contrast1Local',
    defaultSettings.Contrast1
  );
  const [contrast2Local, setContrast2Local] = useLocalStorage(
    'contrast2Local',
    defaultSettings.Contrast2
  );
  const [spin1Local, setSpin1Local] = useLocalStorage(
    'spin1Local',
    defaultSettings.Spin1
  );
  const [spin2Local, setSpin2Local] = useLocalStorage(
    'spin2Local',
    defaultSettings.Spin2
  );
  const [offsetSecondaryLocal, setOffsetSecondaryLocal] = useLocalStorage(
    'offsetSecondaryLocal',
    defaultSettings.OffsetSecondary
  );
  const [offsetAccentLocal, setOffsetAccentLocal] = useLocalStorage(
    'offsetAccentLocal',
    defaultSettings.OffsetAccent
  );

  const [offsetBackgroundLocal, setOffsetBackgroundLocal] = useLocalStorage(
    'offsetBackgroundLocal',
    defaultSettings.OffsetBackground
  );

  const [saturationBackgroundLocal, setSaturationBackgroundLocal] =
    useLocalStorage(
      'saturationBackgroundLocal',
      defaultSettings.SaturationBackground
    );

  const [lightnessBackgroundLocal, setLightnessBackgroundLocal] =
    useLocalStorage(
      'lightnessBackgroundLocal',
      defaultSettings.LightnessBackground
    );
  const [lightnessBackgroundDarkLocal, setLightnessBackgroundDarkLocal] =
    useLocalStorage(
      'lightnessBackgroundDarkLocal',
      defaultSettings.LightnessBackgroundDark
    );

  const [contrast1BackgroundLocal, setContrast1BackgroundLocal] =
    useLocalStorage(
      'contrast1BackgroundLocal',
      defaultSettings.Contrast1Background
    );
  const [contrast2BackgroundLocal, setContrast2BackgroundLocal] =
    useLocalStorage(
      'contrast2BackgroundLocal',
      defaultSettings.Contrast2Background
    );

  // SET STATE
  // Settings
  const [size, setSize] = useState(sizeLocal);
  const [border, setBorder] = useState(borderLocal);
  const [radius, setRadius] = useState(radiusLocal);
  const [fontSize, setFontSize] = useState(fontSizeLocal);
  const [lineHeight, setLineHeight] = useState(lineHeightLocal);
  const [lineHeightSync, setLineHeightSync] = useState(lineHeightSyncLocal);

  const [hue, setHue] = useState(hueLocal);
  const [saturation, setSaturation] = useState(saturationLocal);
  const [lightness, setLightness] = useState(lightnessLocal);
  const [contrast1, setContrast1] = useState(contrast1Local);
  const [contrast2, setContrast2] = useState(contrast2Local);
  const [spin1, setSpin1] = useState(spin1Local);
  const [spin2, setSpin2] = useState(spin2Local);
  const [offsetSecondary, setOffsetSecondary] = useState(offsetSecondaryLocal);
  const [offsetAccent, setOffsetAccent] = useState(offsetAccentLocal);
  const [offsetBackground, setOffsetBackground] = useState(
    offsetBackgroundLocal
  );
  const [saturationBackground, setSaturationBackground] = useState(
    saturationBackgroundLocal
  );
  const [lightnessBackground, setLightnessBackground] = useState(
    lightnessBackgroundLocal
  );
  const [lightnessBackgroundDark, setLightnessBackgroundDark] = useState(
    lightnessBackgroundDarkLocal
  );
  const [contrast1Background, setContrast1Background] = useState(
    contrast1BackgroundLocal
  );
  const [contrast2Background, setContrast2Background] = useState(
    contrast2BackgroundLocal
  );

  // UPDATE LOCL STORAGE
  // Settings
  useEffect(() => {
    setSizeLocal(size);
  }, [setSizeLocal, size]);
  useEffect(() => {
    setBorderLocal(border);
  }, [setBorderLocal, border]);
  useEffect(() => {
    setRadiusLocal(radius);
  }, [setRadiusLocal, radius]);
  useEffect(() => {
    setFontSizeLocal(fontSize);
  }, [setFontSizeLocal, fontSize]);
  useEffect(() => {
    setLineHeightLocal(lineHeight);
  }, [setLineHeightLocal, lineHeight]);
  useEffect(() => {
    setLineHeightSyncLocal(lineHeightSync);
  }, [setLineHeightSyncLocal, lineHeightSync]);
  // Color settings
  useEffect(() => {
    setHueLocal(hue);
  }, [setHueLocal, hue]);
  useEffect(() => {
    setSaturationLocal(saturation);
  }, [setSaturationLocal, saturation]);
  useEffect(() => {
    setLightnessLocal(lightness);
  }, [setLightnessLocal, lightness]);
  useEffect(() => {
    setContrast1Local(contrast1);
  }, [setContrast1Local, contrast1]);
  useEffect(() => {
    setContrast2Local(contrast2);
  }, [setContrast2Local, contrast2]);
  useEffect(() => {
    setSpin1Local(spin1);
  }, [setSpin1Local, spin1]);
  useEffect(() => {
    setSpin2Local(spin2);
  }, [setSpin2Local, spin2]);
  useEffect(() => {
    setOffsetSecondaryLocal(offsetSecondary);
  }, [setOffsetSecondaryLocal, offsetSecondary]);
  useEffect(() => {
    setOffsetAccentLocal(offsetAccent);
  }, [setOffsetAccentLocal, offsetAccent]);
  useEffect(() => {
    setOffsetBackgroundLocal(offsetBackground);
  }, [setOffsetBackgroundLocal, offsetBackground]);
  useEffect(() => {
    setSaturationBackgroundLocal(saturationBackground);
  }, [setSaturationBackgroundLocal, saturationBackground]);
  useEffect(() => {
    setLightnessBackgroundLocal(lightnessBackground);
  }, [setLightnessBackgroundLocal, lightnessBackground]);
  useEffect(() => {
    setLightnessBackgroundDarkLocal(lightnessBackgroundDark);
  }, [setLightnessBackgroundDarkLocal, lightnessBackgroundDark]);
  useEffect(() => {
    setContrast1BackgroundLocal(contrast1Background);
  }, [setContrast1BackgroundLocal, contrast1Background]);
  useEffect(() => {
    setContrast2BackgroundLocal(contrast2Background);
  }, [setContrast2BackgroundLocal, contrast2Background]);

  const resetTheme = () => {
    setSize(defaultSettings.Size);
    setBorder(defaultSettings.Border);
    setRadius(defaultSettings.Radius);
    setFontSize(defaultSettings.FontSize);
    setLineHeight(defaultSettings.LineHeight);
    setLineHeightSync(defaultSettings.LineHeightSync);

    setHue(defaultSettings.Hue);
    setSaturation(defaultSettings.Saturation);
    setLightness(defaultSettings.Lightness);
    setContrast1(defaultSettings.Contrast1);
    setContrast2(defaultSettings.Contrast2);
    setSpin1(defaultSettings.Spin1);
    setSpin2(defaultSettings.Spin2);
    setOffsetSecondary(defaultSettings.OffsetSecondary);
    setOffsetAccent(defaultSettings.OffsetAccent);
    setOffsetBackground(defaultSettings.OffsetBackground);
    setSaturationBackground(defaultSettings.SaturationBackground);
    setLightnessBackground(defaultSettings.LightnessBackground);
    setLightnessBackgroundDark(defaultSettings.LightnessBackgroundDark);
    setContrast1Background(defaultSettings.Contrast1Background);
    setContrast2Background(defaultSettings.Contrast2Background);
  };

  return (
    <SettingsContext.Provider
      value={{
        // Settings
        size,
        setSize,
        border,
        setBorder,
        radius,
        setRadius,
        fontSize,
        setFontSize,
        lineHeight,
        setLineHeight,
        lineHeightSync,
        setLineHeightSync,
        hue,
        setHue,
        saturation,
        setSaturation,
        lightness,
        setLightness,
        contrast1,
        setContrast1,
        contrast2,
        setContrast2,
        spin1,
        setSpin1,
        spin2,
        setSpin2,
        offsetSecondary,
        setOffsetSecondary,
        offsetAccent,
        setOffsetAccent,
        offsetBackground,
        setOffsetBackground,
        saturationBackground,
        setSaturationBackground,
        lightnessBackground,
        setLightnessBackground,
        lightnessBackgroundDark,
        setLightnessBackgroundDark,
        contrast1Background,
        setContrast1Background,
        contrast2Background,
        setContrast2Background,
        resetTheme,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContext;

export { SettingsProvider };
