import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Auth0Provider } from '@auth0/auth0-react';

import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

import { ThemeProvider } from './context/ThemeContext';
import { ColorsProvider } from './context/ColorsContext';
import { SettingsProvider } from './context/SettingsContext';
import { UserProvider } from './context/UserContext';
import { ProjectsProvider } from './context/ProjectsContext';

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain='jessedidthis.auth0.com'
      clientId='O6flrR214VVSyHAkID81vIrE2LaiEITU'
      redirectUri={window.location.origin}
      audience='https://jessedidthis.auth0.com/api/v2/'
      scope='read:current_user update:current_user_metadata'
    >
      <HelmetProvider>
        <Helmet>
          <script
            src='https://kit.fontawesome.com/110a09d988.js'
            crossorigin='anonymous'
          ></script>
        </Helmet>
        <ThemeProvider>
          <SettingsProvider>
            <ColorsProvider>
              <UserProvider>
                <ProjectsProvider>
                  <App />
                </ProjectsProvider>
              </UserProvider>
            </ColorsProvider>
          </SettingsProvider>
        </ThemeProvider>
      </HelmetProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
