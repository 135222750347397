import React, { useState } from 'react';
import Button from '../../Button/Button';
import Confirmation from '../../Confirmation/Confirmation';
import AddCanvas from '../AddCanvas/AddCanvas';
import ButtonSet from '../../Button/ButtonSet';

const CanvasList = (props) => {
  const [isAdding, setIsAdding] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingCanvas, setDeletingCanvas] = useState({});

  const handleToggleDeleting = (canvas) => {
    setIsAdding(false);
    setIsDeleting(true);
    setDeletingCanvas(canvas);
  };

  const handleModeSync = (sync, mode) => {
    props.theme.setModeSync(sync);
    if (sync) {
      props.theme.modes.forEach((themeModes) => {
        if (themeModes === mode) {
          props.theme.setMode(mode);
        }
      });
    }
  };
  const handleMatchMode = (mode) => {
    // if mode is found in matches props.mode = mode
    if (props.theme.modeSync) {
      props.theme.modes.forEach((themeModes) => {
        if (themeModes === mode) {
          props.theme.setMode(mode);
        }
      });
    }
  };

  // const handleToggleAdding = () => {
  //   setIsAddingCanvas(!isAddingCanvase);
  // };

  return (
    <div
      data-testid='CanvasList'
      style={{
        display: 'flex',
      }}
    >
      <label>Canvas:</label>
      <ButtonSet theme={props.theme}>
        {props.colors.modes &&
          props.colors.modes.map((mode, index) => (
            <div key={index}>
              <Button
                onClick={() => {
                  props.colors.setMode(mode);
                  handleMatchMode(mode);
                }}
                active={props.colors.mode === mode}
                size='small'
              >
                {mode}
              </Button>
              <Button
                onClick={() => {
                  handleToggleDeleting(mode);
                }}
                size='small'
                square={true}
              >
                <i className='fal fa-trash'></i>
              </Button>
              {isDeleting && mode == deletingCanvas && (
                <Confirmation
                  isShowing={isDeleting}
                  setIsShowing={setIsDeleting}
                  handleAction={() => {
                    props.colors.removeMode(mode);
                  }}
                  message='Are you sure you want to delete this
                Canvas?'
                  buttonTextYes='Yes, Delete Canvas'
                  buttonTextNo='No, Keep Canvas'
                />
              )}
            </div>
          ))}
      </ButtonSet>
      {isAdding && (
        <AddCanvas
          isAddingCanvase={isAdding}
          setIsAddingCanvas={setIsAdding}
          colors={props.colors}
          theme={props.theme}
        />
      )}

      <div style={{ marginLeft: '10px' }}>
        <Button
          onClick={() => {
            setIsAdding(!isAdding);
            //handleAddMode(colors);
          }}
          active={isAdding}
          size='small'
          square={true}
          color='primary'
        >
          <i className='fal fa-add'></i>
        </Button>
      </div>
      <div style={{ marginLeft: '10px' }}>
        <Button
          onClick={() => {
            handleModeSync(!props.theme.modeSync, props.colors.mode);
          }}
          active={props.theme.modeSync}
          size='small'
          square={true}
        >
          {props.theme.modeSync ? (
            <i className='fal fa-lock'></i>
          ) : (
            <i className='fal fa-unlock'></i>
          )}
        </Button>
      </div>
    </div>
  );
};

CanvasList.propTypes = {};

CanvasList.defaultProps = {};

export default CanvasList;
