import { createDepth } from '../theme/utils/createDepth';
import { createSize } from '../theme/utils/createSize';
import { createFontSize, createLineHeight } from '../theme/utils/createFont';

import {
  colorPurple,
  colorGrayLight,
  colorLDarkForeground,
  colorLightForeground,
  colorPink,
  colorRed,
  colorYellow,
  colorPurplDark,
} from './colors.default';
import { createColorScale } from '../theme/utils/createColorScale';

export const themeDefault = {
  // Modes
  modeAuto: false,
  modePrefered: '',
  modes: ['light', 'dark'],
  mode: 'dark',
  modeSync: false,
  sizeBase: 8,
  sizeUnit: 'px',
  // Colors
  colors: {
    light: {
      primary: colorPurple,
      secondary: colorRed,
      accent: colorYellow,
      background: colorGrayLight,
      foreground: colorLDarkForeground,
      foregroundContrast: colorLightForeground,
    },
    dark: {
      primary: colorPink,
      secondary: colorRed,
      accent: colorYellow,
      background: colorPurplDark,
      foreground: colorLightForeground,
      foregroundContrast: colorLDarkForeground,
    },
  },
  // Sizes
  size: createSize(8, 65, [0, 64], 'px'),
  borders: createSize(1, 17, [0, 16], 'px'),
  radius: createSize(2, 9, [0, 8], 'px'),
  depth: createDepth(2, 9, [0, 8], 'rgb(251, 192, 252)', 0.6, 0.2, 2, 0),
  fontSize: createFontSize(),
  lineHeight: createLineHeight(),
};
