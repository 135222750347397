import styled from '@emotion/styled';
import ThemeContext from '../../context/ThemeContext';

export const FieldSetStyles = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: props.theme.size[1],
}));

const FieldSet = (props) => {
  return (
    <ThemeContext.Consumer>
      {(theme) => <FieldSetStyles theme={theme} {...props} />}
    </ThemeContext.Consumer>
  );
};

export default FieldSet;
