import React, { useState, useEffect } from 'react';
import Button from '../../Button/Button';

const AddCanvas = (props) => {
  const [name, setName] = useState();

  const handleSave = () => {
    props.colors.addMode(name);
    props.setIsAddingCanvas(false);
  };

  const handleCancel = () => {
    props.setIsAddingCanvas(false);
  };

  // Set update loop
  useEffect(() => {}, []);

  return (
    <div data-testid='AddCanvas'>
      <label htmlFor='name'>Name:</label>
      <input
        id='name'
        type='text'
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
      />

      <div>
        <Button
          onClick={() => {
            handleCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleSave();
          }}
        >
          Save Canvas
        </Button>
      </div>
    </div>
  );
};

AddCanvas.propTypes = {};

AddCanvas.defaultProps = {};

export default AddCanvas;
