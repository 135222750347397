import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

import { defaultPRojectData } from '../defaults/project.default';

const defaultState = {};

const ProjectsContext = React.createContext(defaultState);

const ProjectsProvider = ({ url, children }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [projects, setProjects] = useState();
  const [project, setProject] = useState();

  useEffect(() => {
    const getProjects = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://jessedidthis.auth0.com/api/v2/`,
          scope: 'read:current_user',
        });

        if (user) {
          const data = await Axios.get(
            `${process.env.REACT_APP_API_SERVER}/project`,
            {
              headers: {
                Authorization: `bearer ${accessToken}`,
              },
            }
          );
          setProjects(data.data);
        }
      } catch (e) {
        console.log(e.message);
      }
    };
    getProjects();
  }, [getAccessTokenSilently, user?.sub]);

  const addProject = async (p) => {
    try {
      // post to project api
      const accessToken = await getAccessTokenSilently({
        audience: `https://jessedidthis.auth0.com/api/v2/`,
        scope: 'create:current_user',
      });

      const body = defaultPRojectData;
      await Axios.post(`${process.env.REACT_APP_API_SERVER}/project`, body, {
        headers: {
          Authorization: `bearer ${accessToken}`,
        },
        params: {
          name: p.name,
          version: p.version,
        },
      }).then((res) => {
        setProject(res.data);
      });
      // get all projects
      const data = await Axios.get(
        `${process.env.REACT_APP_API_SERVER}/project`,
        {
          headers: {
            Authorization: `bearer ${accessToken}`,
          },
        }
      );
      setProjects(data.data);
    } catch (e) {
      console.log(e.message);
    }
  };

  const saveProject = async (p) => {
    try {
      // post to project api
      const accessToken = await getAccessTokenSilently({
        audience: `https://jessedidthis.auth0.com/api/v2/`,
        scope: 'create:current_user',
      });

      const body = p.data;
      await Axios.post(`${process.env.REACT_APP_API_SERVER}/project`, body, {
        headers: {
          Authorization: `bearer ${accessToken}`,
        },
        params: {
          name: p.name,
          version: p.version,
        },
      }).then((res) => {
        setProject(res.data);
      });
      // get all projects
      const data = await Axios.get(
        `${process.env.REACT_APP_API_SERVER}/project`,
        {
          headers: {
            Authorization: `bearer ${accessToken}`,
          },
        }
      );
      setProjects(data.data);
    } catch (e) {
      console.log(e.message);
    }
  };

  const updateProject = async (p) => {
    try {
      // post to project api
      const accessToken = await getAccessTokenSilently({
        audience: `https://jessedidthis.auth0.com/api/v2/`,
        scope: 'create:current_user',
      });

      const body = p.data;
      await Axios.put(
        `${process.env.REACT_APP_API_SERVER}/project/${p.project_id}`,
        body,
        {
          headers: {
            Authorization: `bearer ${accessToken}`,
          },
          params: {
            name: p.name,
            version: p.version,
          },
        }
      );
      if (p.project_id === project.project_id) {
        setProject(p);
      }
      // get all projects
      const data = await Axios.get(
        `${process.env.REACT_APP_API_SERVER}/project`,
        {
          headers: {
            Authorization: `bearer ${accessToken}`,
          },
        }
      );
      setProjects(data.data);
    } catch (e) {
      console.log(e.message);
    }
  };

  const getProject = async (pId) => {
    if (pId) {
      // get project from database and setProject;
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://jessedidthis.auth0.com/api/v2/`,
          scope: 'read:current_user',
        });

        const data = await Axios.get(
          `${process.env.REACT_APP_API_SERVER}/project/${pId}`,
          {
            headers: {
              Authorization: `bearer ${accessToken}`,
            },
          }
        );
        setProject(data.data);
      } catch (e) {
        console.log(e.message);
      }
    } else {
      setProject(null);
    }
  };

  const deleteProject = async (pId) => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://jessedidthis.auth0.com/api/v2/`,
        scope: 'delete:current_user',
      });

      const userId = user.sub;

      await Axios.delete(`${process.env.REACT_APP_API_SERVER}/project/${pId}`, {
        headers: {
          Authorization: `bearer ${accessToken}`,
        },
        params: {
          userId,
        },
      });
      // get all projects
      const data = await Axios.get(
        `${process.env.REACT_APP_API_SERVER}/project`,
        {
          headers: {
            Authorization: `bearer ${accessToken}`,
          },
        }
      );
      setProjects(data.data);
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <ProjectsContext.Provider
      value={{
        project,
        projects,
        addProject,
        saveProject,
        deleteProject,
        getProject,
        updateProject,
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};

export default ProjectsContext;

export { ProjectsProvider };
