import React, { useState, useEffect } from 'react';
import JSONPretty from 'react-json-pretty';
import styled from '@emotion/styled';
import Slider from 'rc-slider';
import { useAuth0 } from '@auth0/auth0-react';

import Ruler from './Ruler/Ruler';
import TypeTest from './TypeTest/TypeTest';
import Button from '../Button/Button';
import Typography from '../Typography/Typography';
import LoginMessage from '../LoginMessage/LoginMessage';
import Palette from './Palette/Palette';
import ButtonSet from '../Button/ButtonSet';

const SettingsStyles = styled.div((props) => ({
  paddingTop: props.theme.size[4],
}));

const Controls = styled.div((props) => ({
  display: 'flex',
  padding: props.theme.size[2],
  width: `calc(100% - (${props.theme.size[2]} * 2))`,
}));

const Panel = styled.div((props) => ({
  flex: `0 0 calc(50% - (${props.theme.size[2]} * 2))`,
  padding: props.theme.size[2],
}));

const Settings = (props) => {
  const { isAuthenticated } = useAuth0();
  const [button, setButton] = useState();
  const buttonSizes = ['small', 'medium', 'large', 'huge'];
  const buttonsColors = ['default', 'primary', 'secondary', 'danger'];
  // Set update loop
  useEffect(() => {}, []);

  return isAuthenticated ? (
    <SettingsStyles theme={props.theme} data-testid='Settings'>
      <Typography>
        <h1>Settings</h1>
        <Controls theme={props.theme}>
          <Panel theme={props.theme}>
            <h2>Spacing</h2>
            <label>Space</label>
            <div>{props.settings.size}</div>
            <Slider
              min={1}
              max={24}
              onChange={(e) => {
                props.settings.setSize(Number(e));
              }}
              value={props.settings.size}
            />

            <label>Border</label>
            <div>{props.settings.border}</div>
            <Slider
              min={1}
              max={24}
              onChange={(e) => {
                props.settings.setBorder(Number(e));
              }}
              value={props.settings.border}
            />

            <label>Font Size</label>
            <div>{props.settings.fontSize}</div>
            <Slider
              min={1}
              max={24}
              onChange={(e) => {
                props.settings.setFontSize(Number(e));
              }}
              value={props.settings.fontSize}
            />

            <label>Line Height</label>
            <div>{props.settings.settingsLineHeight}</div>
            <Slider
              min={1}
              max={24}
              onChange={(e) => {
                props.settings.setLineHeight(Number(e));
              }}
              value={props.settings.settingsLineHeight}
            />

            <Button
              onClick={() =>
                props.settings.setLineHeightSync(
                  !props.settings.settingsLineHeightSync
                )
              }
              active={props.settings.settingsLineHeightSync}
            >
              Sync to Size
            </Button>
          </Panel>
          <Panel theme={props.theme}>
            <h2>Color</h2>
            <label>Hue</label>
            <Slider min={0} max={365} onChange={(e) => {}} disabled />
            <label>Saturation</label>
            <Slider min={0} max={100} onChange={(e) => {}} disabled />
            <label>Lightness</label>
            <Slider min={0} max={100} onChange={(e) => {}} disabled />
          </Panel>
        </Controls>

        <h2>Buttons</h2>
        {
          // loop through buttonsStyles
          buttonSizes.map((size, index) => (
            <div key={index} style={{ padding: props.theme.size[4] }}>
              <h3>{size}</h3>
              {
                // loop through buttonsColors
                buttonsColors.map((color, index2) => (
                  <div key={index2}>
                    <ButtonSet style={{ marginBottom: props.theme.size[2] }}>
                      <Button
                        theme={props.theme}
                        color={color}
                        size={size}
                        onClick={() => setButton(`${index}-${index2}-1`)}
                        active={button === `${index}-${index2}-1`}
                      >
                        {color}
                      </Button>
                      <Button
                        square={true}
                        theme={props.theme}
                        color={color}
                        size={size}
                        onClick={() => setButton(`${index}-${index2}-2`)}
                        active={button === `${index}-${index2}-2`}
                      >
                        <i className='fas fa-times'></i>
                      </Button>
                    </ButtonSet>
                  </div>
                ))
              }
            </div>
          ))
        }

        <h2>Color</h2>
        {Object.keys(props.theme.colors[props.theme.mode]).map((key) => (
          <div key={key}>
            <Palette
              theme={props.theme}
              colorName={key}
              colors={props.theme.colors[props.theme.mode][key]}
              showNumbers={true}
              height={props.theme.size[12]}
            />
          </div>
        ))}

        <Ruler
          sizes={props.theme.size}
          colors={props.theme.colors[props.theme.mode]}
          height='20'
          showNumbers={true}
          theme={props.theme}
        />

        <TypeTest
          sizes={props.theme.fontSize}
          heights={props.theme.lineHeight}
          showNumbers={true}
          theme={props.theme}
        />

        <JSONPretty id='json-pretty' data={props.theme}></JSONPretty>
      </Typography>
    </SettingsStyles>
  ) : (
    <LoginMessage>Please Login to use this feature</LoginMessage>
  );
};

Settings.propTypes = {};

Settings.defaultProps = {};

export default Settings;
