import React, { useState } from 'react';
import AddSwatch from '../AddSwatch/AddSwatch';
import ColorList from '../ColorList/ColorList';

import Button from '../../Button/Button';

const Colors = (props) => {
  const [isSwatchAdding, setIsSwatchAdding] = useState(false);

  const handleToggleAdding = () => {
    setIsSwatchAdding(!isSwatchAdding);
  };

  const handleRemoveColor = (index) => {
    props.colors.removeColor(index);
  };
  const handleEditColor = (index) => {
    props.colors.editColor(index);
  };
  const handleReorderColors = (result) => {
    props.colors.reorderColors(result);
  };

  return (
    <div data-testid='Colors' style={{ padding: '4px' }}>
      <AddSwatch
        colors={props.colors}
        colorform={props.colorform}
        isSwatchAdding={isSwatchAdding}
        setIsSwatchAdding={setIsSwatchAdding}
        theme={props.theme}
      ></AddSwatch>
      <div>
        <Button
          onClick={() => {
            handleToggleAdding(!isSwatchAdding);
          }}
          active={isSwatchAdding}
          color='primary'
        >
          <i className='fal fa-add'></i>
          Add Color
        </Button>
      </div>
      <ColorList
        colors={props.colors.values[props.colors.mode]}
        handleRemoveColor={handleRemoveColor}
        handleEditColor={handleEditColor}
        handleReorderColors={handleReorderColors}
        theme={props.theme}
      />
    </div>
  );
};

export default Colors;
