import React, { useEffect } from 'react';

export const ProjectsListener = (props) => {
  useEffect(() => {
    if (props.project.project) {
      props.colors.setTheme(
        props.project.project.data.values,
        props.project.project.data.modes,
        props.project.project.data.mode
      );
    }
  }, [props.project.project]);

  return <div>{props.children}</div>;
};
