import React, { useState } from 'react';
import styled from '@emotion/styled';
import JSONPretty from 'react-json-pretty';
import Slider from 'rc-slider';

import Typography from '../Typography/Typography';
import Button from '../Button/Button';
import { HomeScreen } from '../../pages/Home/Home';
import { Columns, Column2, Controls } from './Styles';

export const RulesSizeStyles = styled.div((props) => ({
  '.size-chart-container': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '.size-chart': {
    flex: `0 0 ${props.theme.size[24]}`,
    display: 'flex',
    flexWrap: 'wrap',
  },
  '.size-chart-item': {
    background: props.theme.colors[props.theme.mode].primary[50],
    fontSize: props.theme.fontSize[2],
    lineHeight: props.theme.lineHeight[2],
  },
  '.size-chart-content': {
    padding: `${props.theme.size[1]} ${props.theme.size[0]}`,
    textAlign: 'center',
    border: `1px solid ${props.theme.colors[props.theme.mode].background[0]}`,
  },
  '.size-chart-legend': {
    background: props.theme.colors[props.theme.mode].background[0],
  },
  '.size-chart-label': {
    width: props.theme.size[24],
    padding: `${props.theme.size[1]} ${props.theme.size[0]}`,
    textAlign: 'center',
    border: `1px solid ${props.theme.colors[props.theme.mode].background[0]}`,
    fontSize: props.theme.fontSize[2],
    lineHeight: props.theme.lineHeight[2],
  },

  '.size-chart-border': {
    flex: `0 0 ${props.theme.size[48]}`,
  },
  '.size-chart-border-container': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '.size-chart-border-item': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: props.theme.size[2],
  },
  '.size-chart-border-label': {
    width: props.theme.size[24],
    background: props.theme.colors[props.theme.mode].background[0],
    textAlign: 'center',
    fontSize: props.theme.fontSize[2],
    lineHeight: props.theme.lineHeight[2],
    padding: `${props.theme.size[1]} ${props.theme.size[2]}`,
  },
  '.size-chart-border-item-border': {
    flex: '0 0 50%',
  },

  '.size-chart-radius': {
    flex: `0 0 ${props.theme.size[48]}`,
  },
  '.size-chart-radius-container': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '.size-chart-radius-item': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: props.theme.size[2],
  },
  '.size-chart-radius-label': {
    width: props.theme.size[24],
    background: props.theme.colors[props.theme.mode].background[0],
    textAlign: 'center',
    fontSize: props.theme.fontSize[2],
    lineHeight: props.theme.lineHeight[2],
    padding: `${props.theme.size[1]} ${props.theme.size[2]}`,
  },
  '.size-chart-radius-item-radius': {
    flex: '0 0 50%',
    marginLeft: props.theme.size[2],
    height: props.theme.size[12],
  },
}));
export function RulesSize(props) {
  const [showControls, setShowControls] = useState(false);

  const sizeExample = {
    size: props.settings.size,
    count: 65,
    domain: [0, 64],
    format: 'px',
  };

  const borderExample = {
    size: props.settings.border,
    count: 17,
    domain: [0, 16],
    format: 'px',
  };

  const radiusExample = {
    size: props.settings.radius,
    count: 9,
    domain: [0, 8],
    format: 'px',
  };
  const borderDemo = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
  const radiusDemo = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <HomeScreen theme={props.theme}>
      <RulesSizeStyles theme={props.theme} className='container'>
        <Typography>
          <i
            className='fal fa-2x fa-ruler-combined'
            style={{ marginBottom: props.theme.size[2] }}
          ></i>
          <h2>Size</h2>
          <Columns theme={props.theme}>
            <Column2 theme={props.theme}>
              <h3>Rules</h3>
              <ul>
                <li>Size Palette increments in whole numbers</li>
                <li>Base is divisible by 2, 3, and 4</li>
              </ul>
            </Column2>
            <Column2 theme={props.theme}>
              <h3>Constraints</h3>
              <ul>
                <li>Size values are in px</li>
                <li>Size values are between [0, 64]</li>
                <li>Size Palette increment base evenly</li>
              </ul>
            </Column2>
          </Columns>
          {showControls && (
            <Controls theme={props.theme}>
              <div className='controls-header'>Size</div>
              <div className='controls-actions'>
                <Button onClick={() => setShowControls(false)} square={true}>
                  <i className='fal fa-close'></i>
                </Button>
              </div>
              <div className='control-group'>
                <div className='label'>
                  <label>Space</label>
                  <div>{props.settings.size}</div>
                </div>
                <Slider
                  min={1}
                  max={16}
                  onChange={(e) => {
                    props.settings.setSize(Number(e));
                  }}
                  value={props.settings.size}
                />
              </div>
              <div className='control-group'>
                <div className='label'>
                  <label>Border</label>
                  <div>{props.settings.border}</div>
                </div>
                <Slider
                  min={1}
                  max={24}
                  onChange={(e) => {
                    props.settings.setBorder(Number(e));
                  }}
                  value={props.settings.border}
                />
              </div>
              <div className='control-group'>
                <div className='label'>
                  <label>Radius</label>
                  <div>{props.settings.radius}</div>
                </div>
                <Slider
                  min={0}
                  max={12}
                  onChange={(e) => {
                    props.settings.setRadius(Number(e));
                  }}
                  value={props.settings.radius}
                />
              </div>
            </Controls>
          )}
          <div className='size-chart-container'>
            <div style={{ padding: props.theme.size[4] }}>
              <Button
                onClick={() => setShowControls(!showControls)}
                active={showControls}
                square={true}
              >
                <i className='fal fa-2x fa-sliders-h'></i>
              </Button>
              <JSONPretty id='json-pretty' data={sizeExample}></JSONPretty>
            </div>
            <div className='size-chart-legend'>
              <div className='size-chart-label'>
                24 x {props.theme.size[1]} = {props.theme.size[24]}
              </div>
              <div className='size-chart-label'>
                12 x {props.theme.size[1]} = {props.theme.size[12]}
              </div>
              <div className='size-chart-label'>
                8 x {props.theme.size[1]} = {props.theme.size[8]}
              </div>
              <div className='size-chart-label'>
                6 x {props.theme.size[1]} = {props.theme.size[6]}
              </div>
              <div className='size-chart-label'>
                4 x {props.theme.size[1]} = {props.theme.size[4]}
              </div>
              <div className='size-chart-label'>
                2 x {props.theme.size[1]} = {props.theme.size[2]}
              </div>
              <div className='size-chart-label'>
                {props.theme.size[1]} Base Grid
              </div>
            </div>
            <div className='size-chart' style={{ width: props.theme.size[24] }}>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[24] }}
              >
                <div className='size-chart-content'>24</div>
              </div>

              <div
                className='size-chart-item'
                style={{ width: props.theme.size[12] }}
              >
                <div className='size-chart-content'>12</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[12] }}
              >
                <div className='size-chart-content'>12</div>
              </div>

              <div
                className='size-chart-item'
                style={{ width: props.theme.size[8] }}
              >
                <div className='size-chart-content'>8</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[8] }}
              >
                <div className='size-chart-content'>8</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[8] }}
              >
                <div className='size-chart-content'>8</div>
              </div>

              <div
                className='size-chart-item'
                style={{ width: props.theme.size[6] }}
              >
                <div className='size-chart-content'>6</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[6] }}
              >
                <div className='size-chart-content'>6</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[6] }}
              >
                <div className='size-chart-content'>6</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[6] }}
              >
                <div className='size-chart-content'>6</div>
              </div>

              <div
                className='size-chart-item'
                style={{ width: props.theme.size[4] }}
              >
                <div className='size-chart-content'>4</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[4] }}
              >
                <div className='size-chart-content'>4</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[4] }}
              >
                <div className='size-chart-content'>4</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[4] }}
              >
                <div className='size-chart-content'>4</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[4] }}
              >
                <div className='size-chart-content'>4</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[4] }}
              >
                <div className='size-chart-content'>4</div>
              </div>

              <div
                className='size-chart-item'
                style={{ width: props.theme.size[2] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[2] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[2] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[2] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[2] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[2] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[2] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[2] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[2] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[2] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[2] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[2] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>

              <div
                className='size-chart-item'
                style={{ width: props.theme.size[1] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[1] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[1] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[1] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[1] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[1] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[1] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[1] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[1] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[1] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[1] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[1] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[1] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[1] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[1] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[1] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[1] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[1] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[1] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[1] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[1] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[1] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[1] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
              <div
                className='size-chart-item'
                style={{ width: props.theme.size[1] }}
              >
                <div className='size-chart-content'>&nbsp;</div>
              </div>
            </div>
          </div>
          <div>
            <h2>Borders</h2>
            <Columns theme={props.theme}>
              <Column2 theme={props.theme}>
                <h3>Rules</h3>
                <ul>
                  <li>Border Palette increments in whole numbers</li>
                  <li>Border base size is 1px</li>
                </ul>
              </Column2>
              <Column2 theme={props.theme}>
                <h3>Constraints</h3>
                <ul>
                  <li>Border values are in px</li>
                  <li>Border values are between [0, 16]</li>
                  <li>Border Palette increment base evenly</li>
                </ul>
              </Column2>
            </Columns>
            <div className='size-chart-border-container'>
              <div style={{ padding: props.theme.size[4] }}>
                <JSONPretty id='json-pretty' data={borderExample}></JSONPretty>
              </div>
              <div
                style={{ padding: props.theme.size[4] }}
                className='size-chart-border'
              >
                {
                  // loop through fonborderDemotDemo
                  borderDemo.map((border, key) => (
                    <div key={key} className='size-chart-border-item'>
                      <div className='size-chart-border-label'>
                        Border: {border}
                      </div>
                      <div
                        className='size-chart-border-item-border'
                        style={{
                          height: props.theme.border[border],
                          background:
                            props.theme.colors[props.theme.mode].primary[50],
                        }}
                      ></div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
          <div>
            <h2>Radius</h2>
            <Columns theme={props.theme}>
              <Column2 theme={props.theme}>
                <h3>Rules</h3>
                <ul>
                  <li>Radius Palette increments in whole numbers</li>
                  <li>Radius base size is 4px</li>
                </ul>
              </Column2>
              <Column2 theme={props.theme}>
                <h3>Constraints</h3>
                <ul>
                  <li>Radius values are in px</li>
                  <li>Radius values are between [0, 16]</li>
                  <li>Radius Palette increment base evenly</li>
                </ul>
              </Column2>
            </Columns>
            <div className='size-chart-radius-container'>
              <div style={{ padding: props.theme.size[4] }}>
                <JSONPretty id='json-pretty' data={radiusExample}></JSONPretty>
              </div>
              <div
                style={{ padding: props.theme.size[4] }}
                className='size-chart-radius'
              >
                {
                  // loop through fonradiusDemotDemo
                  radiusDemo.map((radius, key) => (
                    <div key={key} className='size-chart-radius-item'>
                      <div className='size-chart-radius-label'>
                        Radius: {radius}
                      </div>
                      <div
                        className='size-chart-radius-item-radius'
                        style={{
                          borderRadius: props.theme.radius[radius],
                          background:
                            props.theme.colors[props.theme.mode].primary[50],
                        }}
                      ></div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </Typography>
      </RulesSizeStyles>
    </HomeScreen>
  );
}
