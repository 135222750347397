import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { getContrast } from '../../../theme/utils/getContrast';

let RulerStyles = styled.div((props) => ({
  padding: props.theme.size[3]
}));
let RulerSection = styled.div((props) => ({
  position: 'relative',
  height: props.theme.lineHeight[3],
  '&:after': {
    content: '""',
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    background: props.theme.colors[props.theme.mode].background[100],
  },
  '.ruler-label': {
    position: 'absolute',
    left: '100%',
    top: '0',
    color: getContrast(props.theme.colors[props.theme.mode].background[50], [
      props.theme.colors[props.theme.mode].foreground[0],
      props.theme.colors[props.theme.mode].foreground[100],
    ]),
    whiteSpace: 'nowrap',
    zIndex: '100',
    lineHeight: props.theme.lineHeight[3],
    fontSize: props.theme.fontSize[0],
    padding: `0 ${props.theme.size[2]}`,
  },
  '&:hover': {
    '&:after': {
      background: props.theme.colors[props.theme.mode].primary[50],
    },
    '.ruler-label': {
      color: getContrast(props.theme.colors[props.theme.mode].background[50], [
        props.theme.colors[props.theme.mode].primary[50],
        props.theme.colors[props.theme.mode].primary[50],
        props.theme.colors[props.theme.mode].primary[100],
      ]),
    },
  },
}));

const Ruler = (props) => {
  return (
    <RulerStyles data-testid='Ruler' theme={props.theme}>
      <h2>Size</h2>
      {Object.keys(props.sizes).map((key2) => (
        <RulerSection
          theme={props.theme}
          key={key2}
          style={{
            width: props.sizes[key2],
          }}
        >
          <div className='ruler-label'>
            {key2} - {props.sizes[key2]}
          </div>
        </RulerSection>
      ))}
    </RulerStyles>
  );
};

Ruler.propTypes = {
  sizes: PropTypes.object,
  colors: PropTypes.object,
  showNumbers: PropTypes.bool,
  height: PropTypes.number,
  theme: PropTypes.object,
};

Ruler.defaultProps = {
  sizes: {},
  colors: {},
  showNumbers: false,
  height: 10,
  theme: {},
};

export default Ruler;
