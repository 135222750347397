import { getContrast } from '../../theme/utils/getContrast';
import chroma from 'chroma-js';

export function setButtonBaseStyles(props) {
  return {
    cursor: 'pointer',
    border: 0,
    borderRadius: props.theme.radius[1],
    display: 'flex-inline',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid',
    div: {
      marginTop: props.theme.size[1],
    },
  };
}

export function setButtonSize(props) {
  const calcVertPadding = (s, b = 0, lh = 0) => {
    let finalSize;
    finalSize = `calc((${props.theme.size[s]} / 2) - ${props.theme.border[b]} - (${props.theme.lineHeight[lh]} / 2))`;
    return finalSize;
  };
  const calcHorzPadding = (s, b = 0) => {
    let finalSize;
    finalSize = `calc((${props.theme.size[s]} / 2) - ${props.theme.border[b]})`;
    return finalSize;
  };

  let buttonSize = {};

  if (props.square) {
    switch (props.size) {
      case 'small':
        buttonSize = {
          fontSize: props.theme.fontSize[-3],
          lineHeight: props.theme.lineHeight[-3],
          minWidth: props.theme.size[3],
          padding: `${calcVertPadding(3, 1, -3)} ${calcVertPadding(3, 1, -3)}`,
        };
        break;
      case 'medium':
        buttonSize = {
          fontSize: props.theme.fontSize[0],
          lineHeight: props.theme.lineHeight[0],
          minWidth: props.theme.size[4],
          padding: `${calcVertPadding(4, 1, 0)} ${calcVertPadding(4, 1, 0)}`,
        };
        break;
      case 'large':
        buttonSize = {
          fontSize: props.theme.fontSize[2],
          lineHeight: props.theme.lineHeight[2],
          minWidth: props.theme.size[6],
          padding: `${calcVertPadding(6, 1, 2)} ${calcVertPadding(6, 1, 2)}`,
        };
        break;
      case 'huge':
        buttonSize = {
          fontSize: props.theme.fontSize[4],
          lineHeight: props.theme.lineHeight[4],
          minWidth: props.theme.size[10],
          padding: `${calcVertPadding(10, 1, 4)} ${calcVertPadding(10, 1, 4)}`,
        };
        break;
    }
  } else {
    switch (props.size) {
      case 'small':
        buttonSize = {
          fontSize: props.theme.fontSize[-3],
          lineHeight: props.theme.lineHeight[-3],
          padding: `${calcVertPadding(3, 1, -3)} ${calcHorzPadding(3, 1)}`,
          i: {
            marginRight: '0.5rem',
          },
        };
        break;
      case 'medium':
        buttonSize = {
          fontSize: props.theme.fontSize[0],
          lineHeight: props.theme.lineHeight[0],
          padding: `${calcVertPadding(4, 1, 0)} ${calcHorzPadding(4, 1)}`,
          i: {
            marginRight: '0.5rem',
          },
        };
        break;
      case 'large':
        buttonSize = {
          fontSize: props.theme.fontSize[2],
          lineHeight: props.theme.lineHeight[2],
          padding: `${calcVertPadding(6, 1, 2)} ${calcHorzPadding(6, 1)}`,
          i: {
            marginRight: '0.5rem',
          },
        };
        break;
      case 'huge':
        buttonSize = {
          fontSize: props.theme.fontSize[4],
          lineHeight: props.theme.lineHeight[4],
          padding: `${calcVertPadding(10, 1, 4)} ${calcHorzPadding(10, 1)}`,
          i: {
            marginRight: '0.5rem',
          },
        };
        break;
    }
  }
  return buttonSize;
}

export function setButtonColors(props) {
  let buttonColors = {
    base: {},
    active: {},
    hover: {},
  };

  switch (props.color) {
    case 'primary':
      buttonColors.base = {
        backgroundColor: props.theme.colors[props.theme.mode].primary[50],
        borderColor: 'transparent',
        color: getContrast(props.theme.colors[props.theme.mode].primary[50], [
          props.theme.colors[props.theme.mode].foreground[100],
          props.theme.colors[props.theme.mode].foregroundContrast[100],
        ]),
      };
      buttonColors.active = {
        backgroundColor: props.theme.colors[props.theme.mode].primary[75],
        borderColor: 'transparent',
        color: getContrast(props.theme.colors[props.theme.mode].primary[75], [
          props.theme.colors[props.theme.mode].foreground[100],
          props.theme.colors[props.theme.mode].foregroundContrast[100],
        ]),
      };
      buttonColors.hover = {
        backgroundColor: props.theme.colors[props.theme.mode].primary[25],
        borderColor: 'transparent',
        color: getContrast(props.theme.colors[props.theme.mode].primary[25], [
          props.theme.colors[props.theme.mode].foreground[100],
          props.theme.colors[props.theme.mode].foregroundContrast[100],
        ]),
      };
      break;
    case 'secondary':
      buttonColors.base = {
        backgroundColor: props.theme.colors[props.theme.mode].secondary[50],
        borderColor: 'transparent',
        color: getContrast(props.theme.colors[props.theme.mode].secondary[50], [
          props.theme.colors[props.theme.mode].foreground[100],
          props.theme.colors[props.theme.mode].foregroundContrast[100],
        ]),
      };
      buttonColors.active = {
        backgroundColor: props.theme.colors[props.theme.mode].secondary[75],
        borderColor: 'transparent',
        color: getContrast(props.theme.colors[props.theme.mode].secondary[75], [
          props.theme.colors[props.theme.mode].foreground[100],
          props.theme.colors[props.theme.mode].foregroundContrast[100],
        ]),
      };
      buttonColors.hover = {
        backgroundColor: props.theme.colors[props.theme.mode].secondary[25],
        borderColor: 'transparent',
        color: getContrast(props.theme.colors[props.theme.mode].secondary[25], [
          props.theme.colors[props.theme.mode].foreground[100],
          props.theme.colors[props.theme.mode].foregroundContrast[100],
        ]),
      };
      break;
    case 'danger':
      buttonColors.base = {
        backgroundColor: props.theme.colors[props.theme.mode].primary[50],
        borderColor: 'transparent',
        color: getContrast(props.theme.colors[props.theme.mode].primary[50], [
          props.theme.colors[props.theme.mode].foreground[100],
          props.theme.colors[props.theme.mode].foregroundContrast[100],
        ]),
      };
      buttonColors.active = {
        backgroundColor: props.theme.colors[props.theme.mode].primary[75],
        borderColor: 'transparent',
        color: getContrast(props.theme.colors[props.theme.mode].primary[75], [
          props.theme.colors[props.theme.mode].foreground[100],
          props.theme.colors[props.theme.mode].foregroundContrast[100],
        ]),
      };
      buttonColors.hover = {
        backgroundColor: props.theme.colors[props.theme.mode].primary[25],
        borderColor: 'transparent',
        color: getContrast(props.theme.colors[props.theme.mode].primary[25], [
          props.theme.colors[props.theme.mode].foreground[100],
          props.theme.colors[props.theme.mode].foregroundContrast[100],
        ]),
      };
      break;
    default:
      buttonColors.base = {
        backgroundColor: chroma(
          props.theme.colors[props.theme.mode].foreground[0]
        )
          .alpha(0.2)
          .css(),
        color: props.theme.colors[props.theme.mode].foreground[100],
        borderColor: 'transparent',
      };
      buttonColors.active = {
        backgroundColor: chroma(
          props.theme.colors[props.theme.mode].foreground[0]
        )
          .alpha(0.4)
          .css(),
        color: props.theme.colors[props.theme.mode].foreground[100],
        borderColor: 'transparent',
      };
      buttonColors.hover = {
        backgroundColor: chroma(
          props.theme.colors[props.theme.mode].foreground[0]
        )
          .alpha(0.15)
          .css(),
        color: props.theme.colors[props.theme.mode].foreground[100],
        borderColor: 'transparent',
      };
      break;
  }

  return buttonColors;
}
