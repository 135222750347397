import React from 'react';

import { getMiddleColor } from '../../../theme/utils/getMiddleColor';
import { getContrast } from '../../../theme/utils/getContrast';
import Palette from '../Palette/Palette';
import Button from '../../Button/Button';

const ColorCanvas = (props) => {
  const colors = props.colors?.values[props.colors.mode];

  const handleRemoveColor = (index) => {
    props.colors.removeColor(index);
  };

  const listItems =
    colors &&
    Object.keys(colors).map((key) => (
      <div key={key}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '4px',
            background: getMiddleColor(colors[key].value),
            color: getContrast(getMiddleColor(colors[key].value), [
              '#ffffff',
              '#000000',
            ]),
          }}
        >
          {key} - {colors[key].name} - {getMiddleColor(colors[key].value)}
        </div>
        <Palette
          color={colors[key].value}
          count={colors[key].count}
          domain={colors[key].domain}
          spin={colors[key].spin}
          contrast={colors[key].contrast}
          format={colors[key].format}
          height={props.theme.size[12]}
          showNumbers={true}
        />
      </div>
    ));
  return (
    <div data-testid='ColorCanvas'>
      <div style={{ fontWeight: '600' }}>Colors</div>
      {listItems}
      <br />
      <br />
    </div>
  );
};

ColorCanvas.propTypes = {};

ColorCanvas.defaultProps = {};

export default ColorCanvas;
