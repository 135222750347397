import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const defaultState = {};

const UserContext = React.createContext(defaultState);

const UserProvider = ({ url, children }) => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  const [profile, setProfile] = useState();

  const createProfile = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://jessedidthis.auth0.com/api/v2/`,
        scope: 'read:current_user',
      });

      if (user) {
        const userId = user.sub;
        // use axios post to create a profile at ${process.env.REACT_APP_API_SERVER}//profile
        Axios.post(
          `${process.env.REACT_APP_API_SERVER}/profile`,
          {
            userId: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              name: user.name,
              email: user.email,
            },
          }
        ).then((res) => setProfile(res.data));
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://jessedidthis.auth0.com/api/v2/`,
          scope: 'read:current_user',
        });

        if (user) {
          const data = await Axios.get(
            `${process.env.REACT_APP_API_SERVER}/profile`,
            {
              headers: {
                Authorization: `bearer ${accessToken}`,
              },
            }
          );
          if (data.data) {
            setProfile(data.data);
          } else {
            createProfile();
          }
        }
      } catch (e) {
        console.log(e.message);
      }
    };
    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub]);

  return (
    <UserContext.Provider
      value={{
        isLoading,
        isAuthenticated,
        profile,
        user,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;

export { UserProvider };
