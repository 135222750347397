import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useAuth0 } from '@auth0/auth0-react';

import ThemeContext from '../../context/ThemeContext';
import LoginButton from '../Button/LoginButton';

const LoginMessageStyles = styled.div((props) => ({
  padding: props.theme.size[4],
}));

const LoginMessage = (props) => {
  const { isLoading } = useAuth0();

  // Set update loop
  useEffect(() => {}, []);
  return (
    <ThemeContext.Consumer>
      {(theme) =>
        !isLoading ? (
          <LoginMessageStyles data-testid='LoginMessage' theme={theme}>
            {props.children}
            <div>
              <LoginButton />
            </div>
          </LoginMessageStyles>
        ) : (
          <div>Loading...</div>
        )
      }
    </ThemeContext.Consumer>
  );
};

LoginMessage.propTypes = {};

LoginMessage.defaultProps = {};

export default LoginMessage;
