import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ThemeContext from '../../context/ThemeContext';
import chroma from 'chroma-js';

import { setFieldSize, setFieldColors, setFieldBaseStyles } from './utils';

const InputFieldStyles = styled.div((props) => ({
  padding: props.theme.size[1],
  display: 'flex',
  flexDirection: props.direction,
  alignItems: props.direction === 'row' ? 'center' : 'flex-start',
  label: {
    flex: '1 1 auto',
    color: props.theme.colors[props.theme.mode].foreground[0],
    fontSize: props.theme.fontSize[-3],
    lineHeight: props.theme.lineHeight[-3],
    ...(props.direction === 'column' && {
      marginBottom: `calc(${props.theme.size[1]} / 2)`,
    }),
  },
  input: {
    ...props.fieldBase,
    ...props.size,
    ...props.color,
    '&:hover': {
      ...props.colorHover,
    },
  },
}));

const InputField = (props) => {
  const [fieldBase, setFieldBase] = React.useState(setFieldBaseStyles(props));
  const [size, setSize] = React.useState();
  const [color, setColor] = React.useState();
  const [colorActive, setColorActive] = React.useState();
  const [colorHover, setColorHover] = React.useState();

  // set Size
  useEffect(() => {
    setSize(setFieldSize(props));
  }, [props.size, props.square]);

  // set Color
  useEffect(() => {
    const themeColors = setFieldColors(props);
    setColor(themeColors.base);
    setColorActive(themeColors.active);
    setColorHover(themeColors.hover);
  }, [props.color, props.theme.mode]);

  return (
    <InputFieldStyles
      data-testid='InputField'
      theme={props.theme}
      size={size}
      color={props.active ? colorActive : color}
      colorHover={props.active ? colorActive : colorHover}
      fieldBase={fieldBase}
      direction={props.direction}
    >
      {props.children}
    </InputFieldStyles>
  );
};

InputField.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'huge']),
  color: PropTypes.oneOf(['default', 'danger']),
  direction: PropTypes.oneOf(['column', 'row']),
};

InputField.defaultProps = {
  children: null,
  size: 'medium',
  color: '',
  direction: 'row',
};

const InputFieldThemed = (props) => {
  return (
    <ThemeContext.Consumer>
      {(theme) => <InputField theme={theme} {...props} />}
    </ThemeContext.Consumer>
  );
};

export default InputFieldThemed;
