export const defaultSettings = {
  //Size
  Size: 8,
  Border: 1,
  Radius: 4,
  FontSize: 16,
  LineHeight: 16,
  LineHeightSync: true,
  // Color
  Hue: 300,
  Saturation: 90,
  Lightness: 38,
  Contrast1: -20,
  Contrast2: 32,
  Spin1: 29,
  Spin2: -11,
  // Secondary
  OffsetSecondary: -30,
  OffsetAccent: 30,
  // Background
  OffsetBackground: -30,
  SaturationBackground: 10,
  LightnessBackground: 96,
  LightnessBackgroundDark: 18,
  Contrast1Background: -9,
  Contrast2Background: 6,
};
