import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Typography from '../../Typography/Typography';
import Button from '../../Button/Button';

const AddProjectStyles = styled.div((props) => ({
  padding: props.theme.size[4],
}));

const AddProject = (props) => {
  const [name, setName] = useState('Untitled');

  // Set update loop
  useEffect(() => {}, []);

  const handleToggleAdding = (toggle) => {
    props.setIsAdding(toggle);
  };

  const handleAddProject = () => {
    props.project.addProject({
      name: name,
      version: 0,
    });
    props.setIsAdding(false);
  };

  return (
    <Typography>
      <AddProjectStyles data-testid='AddProject' theme={props.theme}>
        <div>New Project</div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleAddProject(e.target.elements.name.value);
          }}
        >
          <label htmlFor='color'>Project Name: </label>
          <input
            name='name'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Button size='small' onClick={() => handleToggleAdding(false)}>
            Cancel
          </Button>
          <Button size='small'>Create Project</Button>
        </form>
      </AddProjectStyles>
    </Typography>
  );
};

AddProject.propTypes = {};

AddProject.defaultProps = {};

export default AddProject;
