var chroma = require('chroma-js');

export const getContrast = (
  background = '#ffff00',
  foreground = ['#ffffff', '#dddddd', '#000000']
) => {
  if (!background || !foreground) {
    return false;
  }
  let contrastColor = foreground[0];
  let contrast = chroma.contrast(background, foreground[0]);
  // loop foreground colors
  for (let i = 1; i < foreground.length; i++) {
    if (chroma.contrast(background, foreground[i]) > contrast) {
      contrast = chroma.contrast(background, foreground[i]);
      contrastColor = foreground[i];
    }
  }
  return contrastColor;
};

export const getContrastRatio = (color1 = '#ffff00', color2 = '#ffffff') => {
  const contrast = chroma.contrast(color1, color2).toFixed(2);
  // return first decimal place of chroma.contrast(color1, color2)
  return {
    ratio: contrast,
    pass: contrast >= 4.5,
  };
};
