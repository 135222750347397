import React from 'react';
import JSONPretty from 'react-json-pretty';
import { useAuth0 } from '@auth0/auth0-react';
import styled from '@emotion/styled';

import ThemeContext from '../../context/ThemeContext';
import UserContext from '../../context/UserContext';
import LoginMessage from '../../components/LoginMessage/LoginMessage';

const ProfileStyles = styled.div((props) => ({
  padding: props.theme.size[8],
}));

const Profile = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <UserContext.Consumer>
          {(user) =>
            isAuthenticated ? (
              <ProfileStyles theme={theme}>
                <img src={user.user.picture} alt={user.user.name} />
                <h2>{user.user.name}</h2>
                <p>{user.user.email}</p>
                <JSONPretty id='json-pretty' data={user}></JSONPretty>
              </ProfileStyles>
            ) : (
              <LoginMessage>
                <p>You are not logged in</p>
              </LoginMessage>
            )
          }
        </UserContext.Consumer>
      )}
    </ThemeContext.Consumer>
  );
};

export default Profile;
