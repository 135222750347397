import React, { useState } from 'react';
import styled from '@emotion/styled';

import ThemeContext from '../../context/ThemeContext';
import ProjectsContext from '../../context/ProjectsContext';
import ColorsContext from '../../context/ColorsContext';

import ButtonSet from '../../components/Button/ButtonSet';
import Button from '../../components/Button/Button';
import EditProject from '../../components/Project/EditProject/EditProject';
import Confirmation from '../../components/Confirmation/Confirmation';

const ProjectsStyles = styled.div((props) => ({
  padding: props.theme.size[4],
}));
const ProjectStyles = styled.div((props) => ({
  padding: props.theme.size[4],
  '.load-project:hover': {
    backgroundColor: props.theme.colors[props.theme.mode].background[20],
    cursor: 'pointer',
  },
}));

const Projects = (props) => {
  const [isEditing, setIsEditing] = useState();
  const [editingProject, setEditingProject] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingProject, setDeletingProject] = useState({});

  const handleToggleEditing = (project) => {
    setIsEditing(true);
    setIsDeleting(false);
    setEditingProject(project);
  };

  const handleToggleDeleting = (project) => {
    setIsEditing(false);
    setIsDeleting(true);
    setDeletingProject(project);
  };

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <ProjectsContext.Consumer>
          {(project) => (
            <ColorsContext.Consumer>
              {(colors) => (
                <ProjectsStyles data-testid='Projects' theme={theme}>
                  <h1>Projects</h1>
                  {
                    // if project.projects exist, loop through them and display them, otherwise display a message
                    project.projects ? (
                      project.projects.length > 0 ? (
                        project.projects.map((p) => (
                          <div key={p.id}>
                            {isEditing &&
                            editingProject.project_id === p.project_id ? (
                              <EditProject
                                theme={theme}
                                project={project}
                                setIsEditing={setIsEditing}
                                editingProject={p}
                              />
                            ) : (
                              <ProjectStyles theme={theme}>
                                {
                                  // if this is open project
                                  project.project &&
                                    project.project.project_id ===
                                      p.project_id && <div>Open</div>
                                }
                                <div
                                  className='load-project'
                                  onClick={() => {
                                    project.getProject(p.project_id);
                                  }}
                                >
                                  <div>{p.name}</div>
                                  <div>{p.project_id}</div>
                                  <div>{p.date}</div>
                                </div>
                                <ButtonSet theme={theme}>
                                  <Button
                                    onClick={() => {
                                      handleToggleEditing(p);
                                    }}
                                    square={true}
                                  >
                                    <i className='fal fa-edit'></i>
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      handleToggleDeleting(p);
                                    }}
                                    square={true}
                                  >
                                    <i className='fal fa-trash'></i>
                                  </Button>
                                </ButtonSet>
                                {isDeleting &&
                                  deletingProject.project_id ===
                                    p.project_id && (
                                    <Confirmation
                                      isShowing={isDeleting}
                                      setIsShowing={setIsDeleting}
                                      handleAction={() => {
                                        project.deleteProject(p.project_id);
                                      }}
                                      message='Are you sure you want to delete this
                                    project?'
                                      buttonTextYes='Yes, Delete Project'
                                      buttonTextNo='No, Keep Project'
                                    />
                                  )}
                              </ProjectStyles>
                            )}
                          </div>
                        ))
                      ) : (
                        <p>No projects to display</p>
                      )
                    ) : (
                      <p>Loading</p>
                    )
                  }
                </ProjectsStyles>
              )}
            </ColorsContext.Consumer>
          )}
        </ProjectsContext.Consumer>
      )}
    </ThemeContext.Consumer>
  );
};

Projects.propTypes = {};

Projects.defaultProps = {};

export default Projects;
