import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import ThemeContext from '../../context/ThemeContext';

const TypographyStyles = styled.div((props) => ({
  h1: {
    fontSize: props.theme.fontSize[16],
    lineHeight: props.theme.lineHeight[16],
    fontWeight: 700,
    marginBottom: props.theme.size[4],
    color: props.theme.colors[props.theme.mode].foreground[50],
  },
  h2: {
    fontSize: props.theme.fontSize[12],
    lineHeight: props.theme.lineHeight[12],
    fontWeight: 700,
    marginBottom: props.theme.size[4],
    color: props.theme.colors[props.theme.mode].foreground[50],
  },
  h3: {
    fontSize: props.theme.fontSize[10],
    lineHeight: props.theme.lineHeight[10],
    fontWeight: 700,
    marginBottom: props.theme.size[4],
    color: props.theme.colors[props.theme.mode].foreground[50],
  },
  h4: {
    fontSize: props.theme.fontSize[-1],
    lineHeight: props.theme.lineHeight[2],
    fontWeight: 600,
    color: props.theme.colors[props.theme.mode].foreground[0],
    textTransform: 'uppercase',
    letterSpacing: '0.1rem',
  },
  p: {
    fontSize: props.theme.fontSize[2],
    lineHeight: props.theme.lineHeight[2],
    marginBottom: props.theme.size[4],
    color: props.theme.colors[props.theme.mode].foreground[0],
    strong: {
      color: props.theme.colors[props.theme.mode].foreground[100],
    },
  },
  img: {
    maxWidth: '100%',
    marginBottom: props.theme.size[4],
  },
  ul: {
    marginBottom: props.theme.size[3],
    marginLeft: props.theme.size[4],
  },
  li: {
    fontSize: props.theme.fontSize[4],
    lineHeight: props.theme.lineHeight[4],
    marginBottom: props.theme.size[1],
    color: props.theme.colors[props.theme.mode].foreground[0],
    listStyleType: 'circle',
    strong: {
      color: props.theme.colors[props.theme.mode].foreground[100],
    },
  },
  strong: {
    fontWeight: 800,
  },
  hr: {
    border: 0,
    height: props.theme.border[1],
    background: props.theme.colors[props.theme.mode].foreground[0],
    opacity: 0.4,
    margin: `${props.theme.size[2]} 0`,
  },
  '.__json-pretty__': {
    fontSize: props.theme.fontSize[6],
    lineHeight: props.theme.lineHeight[6],
    color: props.theme.colors[props.theme.mode].foreground[50],
    background: props.theme.colors[props.theme.mode].background[0],
    padding: props.theme.size[4],
    maxHeight: props.theme.size[64],
    ' .__json-key__': {
      color: props.theme.colors[props.theme.mode].primary[50],
    },

    '  .__json-value__': {
      color: props.theme.colors[props.theme.mode].secondary[70],
    },

    '  .__json-string__': {
      color: props.theme.colors[props.theme.mode].secondary[30],
    },

    '.__json-boolean__': {
      color: props.theme.colors[props.theme.mode].accent[50],
    },

    '.__json-pretty-error__': {
      lineHeight: props.theme.lineHeight[4],
      color: props.theme.colors[props.theme.mode].foreground[50],
      background: props.theme.colors[props.theme.mode].background[0],
    },
  },
}));

const Typography = (props) => {
  // Set update loop
  useEffect(() => {}, []);

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <TypographyStyles theme={theme} data-testid='Typography'>
          {props.children}
        </TypographyStyles>
      )}
    </ThemeContext.Consumer>
  );
};

Typography.propTypes = {};

Typography.defaultProps = {};

export default Typography;
