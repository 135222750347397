import React from 'react';
import Typography from '../Typography/Typography';
import NasaDesignManual from '../../img/nasa-design-manual.jpg';
import Bootstrap from '../../img/bootstrap.jpg';
import MaterialDesign from '../../img/material-design.jpg';
import { HomeScreen } from '../../pages/Home/Home';
import { Columns, Column3 } from './Styles';

export function WhatIs(props) {
  return (
    <HomeScreen theme={props.theme}>
      <div className='container'>
        <Typography>
          <i
            className='fal fa-2x fa-ruler-cubes'
            style={{ marginBottom: props.theme.size[2] }}
          ></i>
          <h2>What is a Design System?</h2>
          <ul>
            <li>
              A Design System is <strong>not</strong> what you design
            </li>
            <li>
              A Design System is <strong>not</strong> what you develop
            </li>
            <li>
              A Design System is only what is documented &amp; implemented
            </li>
          </ul>
          <Columns theme={props.theme}>
            <Column3 theme={props.theme}>
              <img
                src={NasaDesignManual}
                alt='NASA Graphics Standards Manual'
              />
              <h3>NASA Graphics Standards Manual</h3>
              <p>1976</p>
            </Column3>
            <Column3 theme={props.theme}>
              <img src={Bootstrap} alt='Bootstrap Website' />
              <h3>Bootstrap</h3>
              <p>2011</p>
            </Column3>
            <Column3 theme={props.theme}>
              <img src={MaterialDesign} alt='Material Design' />
              <h3>Material Design</h3>
              <p>2014-Now</p>
            </Column3>
          </Columns>
          <h3>The best Design System is the one that you use.</h3>
        </Typography>
      </div>
    </HomeScreen>
  );
}
