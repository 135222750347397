export const defaultPRojectData = {
  values: {
    light: [
      {
        id: 'b8241a79-d650-48f0-9d21-6a0825e417d3',
        name: 'Primary',
        value: ['#4965f5'],
        count: 21,
        domain: [0, 100],
        contrast: [-32, 19],
        spin: [29, -11],
        format: 'hex',
      },
    ],
    dark: [
      {
        id: 'ba75411c-c9fd-4aa0-9c3b-5e97f80ded8a',
        name: 'Primary',
        value: ['#ba09b8'],
        count: 21,
        domain: [0, 100],
        contrast: [-13, 36],
        spin: [-20, 20],
        format: 'hex',
      },
    ],
  },
  modes: ['light', 'dark'],
  mode: 'dark',
};
