import React from 'react';
import styled from '@emotion/styled';

import Button from '../../Button/Button';

const Menubar = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: props.theme.size[1],
  gap: props.theme.size[1],
}));

const ModeSwitch = (props) => {
  return (
    <Menubar theme={props.theme} data-testid='ModeSwitch'>
      {props.theme.modes.map((mode, index) => (
        <div key={index}>
          <Button
            onClick={() => {
              props.theme.setModeManually(mode);
            }}
            active={props.theme.mode === mode}
            square={true}
            size='large'
          >
            {mode === 'light' ? (
              <i className='fas fa-sun'></i>
            ) : mode === 'dark' ? (
              <i className='fas fa-moon'></i>
            ) : (
              mode
            )}
          </Button>
        </div>
      ))}
      <Button
        onClick={() => {
          props.theme.setModeAuto(!props.theme.modeAuto);
        }}
        active={props.theme.modeAuto}
        square={true}
        size='large'
      >
        <i className='fas fa-magic'></i>
      </Button>
    </Menubar>
  );
};

ModeSwitch.propTypes = {};

ModeSwitch.defaultProps = {};

export default ModeSwitch;
