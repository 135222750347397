import chroma from 'chroma-js';

export const createDepth = (
  size = 8,
  count = 65,
  domain = [0, 64],
  color = '#000000',
  opacity = 0.5,
  darken = 0.2,
  hOffset = 0,
  vOffset = 2
) => {
  if (isNaN(size) || size > 256 || size < 0) {
    return 'Size must be a number between 1 and 256';
  }
  if (isNaN(count) || count > 1000 || count < 1) {
    return 'Count must be a number between 1 and 1000';
  }
  if (isNaN(domain[0]) || isNaN(domain[1])) {
    return 'Domain must be two numbers';
  }

  const keyIncrement = (domain[1] - domain[0]) / (count - 1);

  const shadowColor = chroma(color).alpha(opacity).darken(darken).hex();

  let scale = {};
  for (let i = 0; i < count; i++) {
    const sizeName = Math.floor(domain[0] + keyIncrement * i);
    scale[sizeName] = `${i * hOffset}px ${i * vOffset}px ${i * size}px ${shadowColor}`
  }
  return scale;
};
