import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import styled from '@emotion/styled';

import UserContext from '../../context/UserContext';
import ThemeContext from '../../context/ThemeContext';
import LoginButton from '../Button/LoginButton';

const AvatarStyles = styled.div((props) => ({
  padding: props.theme.size[1],
  img: {
    width: props.theme.size[props.size],
    height: props.theme.size[props.size],
    objectFit: 'cover',
    borderRadius: props.theme.size[props.size],
  },
}));

const Avatar = (props) => {
  const { isAuthenticated, isLoading } = useAuth0();

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <UserContext.Consumer>
          {(user) =>
            !isLoading &&
            (isAuthenticated ? (
              <AvatarStyles theme={theme} size={props.size}>
                <Link to='/profile'>
                  {user.user.picture ? (
                    <img src={user.user.picture} alt={user.user.name} />
                  ) : (
                    'AV'
                  )}
                </Link>
              </AvatarStyles>
            ) : (
              <LoginButton />
            ))
          }
        </UserContext.Consumer>
      )}
    </ThemeContext.Consumer>
  );
};

Avatar.propTypes = {
  size: PropTypes.number,
};

Avatar.defaultProps = {
  size: 4,
};

export default Avatar;
