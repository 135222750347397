// file: /components/LinkButton.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { useLocation } from 'react-router-dom';

import Button from './Button';

const LinkButton = (props) => {
  const {
    history,
    location,
    match,
    staticContext,
    to,
    onClick,
    // ⬆ filtering out props that `button` doesn’t know what to do with.
    ...rest
  } = props;

  return (
    <Button
      color={props.color}
      size={props.size}
      square={props.square}
      active={location.pathname === to}
      onClick={(event) => {
        onClick && onClick(event);
        history.push(to);
      }}
    >
      {props.children}
    </Button>
  );
};

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
  square: PropTypes.bool,
};

export default withRouter(LinkButton);
