import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { useAuth0 } from '@auth0/auth0-react';
import Axios from 'axios';

import './App.css';

import Home from './pages/Home/Home';
import Profile from './pages/Profile/Profile';
import { Workspace } from './templates/Workspace';

import LogoutButton from './components/Button/LogoutButton';
import Settings from './components/Settings/Settings';
import ModeSwitch from './components/Settings/ModeSwitch/ModeSwitch';
import Avatar from './components/Profile/Avatar';

import ThemeContext from './context/ThemeContext';
import SettingsContext from './context/SettingsContext';
import { SettingsListener } from './listener/Settings.Listener';

const AppStyles = styled.div((props) => ({
  color: props.theme.colors[props.theme.mode].foreground[50],
  minHeight: '100vh',
  a: {
    color: props.theme.colors[props.theme.mode].primary[50],
  },
  '&:before': {
    zIndex: -1,
    content: '""',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: [
      props.theme.colors[props.theme.mode].background[0],
      `linear-gradient(23deg, ${
        props.theme.colors[props.theme.mode].background[0]
      } 0%, ${props.theme.colors[props.theme.mode].background[30]} 35%, ${
        props.theme.colors[props.theme.mode].background[50]
      } 100%)`,
    ],
  },
}));

function App() {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [profile, setProfile] = useState();

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://jessedidthis.auth0.com/api/v2/`,
          scope: 'read:current_user',
        });

        console.log('accessToken', accessToken);

        if (user) {
          const testing = await Axios.get(
            `${process.env.REACT_APP_API_SERVER}/profile`,
            {
              headers: {
                Authorization: `bearer ${accessToken}`,
              },
            }
          );
          setProfile(testing.data);
        }
      } catch (e) {
        console.log(e.message);
      }
    };

    const getUserMetadata2 = async () => {
      try {
        const testing2 = await Axios.get(
          `${process.env.REACT_APP_API_SERVER}/`,
          {}
        );
        console.log('testing2', testing2);
      } catch (e) {
        console.log(e.message);
      }
    };

    getUserMetadata2();
    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub]);

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <SettingsContext.Consumer>
          {(settings) => (
            <AppStyles theme={theme}>
              {/* <SettingsListener theme={theme} settings={settings} /> */}
              <Router>
                <div
                  style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    zIndex: 100,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Avatar />
                    {isAuthenticated && <LogoutButton />}
                  </div>
                  <ul
                    style={{
                      display: 'flex',
                      marginLeft: theme.size[4],
                    }}
                  >
                    <li style={{ marginLeft: '10px' }}>
                      <Link to='/'>Home</Link>
                    </li>
                    <li style={{ marginLeft: '10px' }}>
                      <Link to='/app'>App</Link>
                    </li>
                    <li style={{ marginLeft: '10px' }}>
                      <Link to='/settings'>Settings</Link>
                    </li>
                    {isAuthenticated && (
                      <li style={{ marginLeft: '10px' }}>
                        <Link to='/profile'>Profile</Link>
                      </li>
                    )}
                  </ul>
                  <ModeSwitch theme={theme} />
                </div>

                <Switch>
                  <Route path='/app'>
                    <Workspace theme={theme} />
                  </Route>
                  <Route path='/profile'>
                    <Profile theme={theme} />
                  </Route>
                  <Route path='/settings' exact>
                    <Settings settings={settings} theme={theme} />
                  </Route>
                  <Route path='/' exact>
                    <Home settings={settings} theme={theme} />
                  </Route>
                </Switch>
                <div
                  className='Status'
                  style={{
                    padding: `${theme.size[1]} ${theme.size[2]}`,
                    background: theme.colors[theme.mode].background[0],
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    &copy; 2021 &nbsp;
                    {process.env.NODE_ENV}
                    {process.env.REACT_APP_API_SERVER}
                  </div>
                  <div>
                    Made hastily by{' '}
                    <a
                      href='http://www.JesseStewart.com'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Jesse Stewart
                    </a>
                  </div>
                </div>
              </Router>
            </AppStyles>
          )}
        </SettingsContext.Consumer>
      )}
    </ThemeContext.Consumer>
  );
}

export default App;
