import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import ThemeContext from '../../context/ThemeContext';
import { getContrast } from '../../theme/utils/getContrast';
import chroma from 'chroma-js';

import { setButtonSize, setButtonColors, setButtonBaseStyles } from './utils';

const ButtonStyles = styled.button((props) => ({
  ...props.buttonBase,
  ...props.size,
  ...props.color,
  '&:hover': {
    ...props.colorHover,
  },
}));

const Button = (props) => {
  const [buttonBase, setButtonBase] = React.useState(
    setButtonBaseStyles(props)
  );
  const [size, setSize] = React.useState();
  const [color, setColor] = React.useState();
  const [colorActive, setColorActive] = React.useState();
  const [colorHover, setColorHover] = React.useState();

  // set Size
  useEffect(() => {
    setSize(setButtonSize(props));
  }, [props.size, props.square]);

  // set Color
  useEffect(() => {
    const themeColors = setButtonColors(props);
    setColor(themeColors.base);
    setColorActive(themeColors.active);
    setColorHover(themeColors.hover);
  }, [props.color, props.theme.mode]);

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <ButtonStyles
      className={props.className}
      onClick={handleClick}
      theme={props.theme}
      active={props.active}
      size={size}
      color={props.active ? colorActive : color}
      colorHover={props.active ? colorActive : colorHover}
      buttonBase={buttonBase}
    >
      {props.children}
    </ButtonStyles>
  );
};

Button.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large', 'huge']),
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'danger']),
  square: PropTypes.bool,
};

Button.defaultProps = {
  size: 'medium',
  color: '',
  square: false,
};

const ButtonThemed = (props) => {
  return (
    <ThemeContext.Consumer>
      {(theme) => <Button theme={theme} {...props} />}
    </ThemeContext.Consumer>
  );
};

export default ButtonThemed;
