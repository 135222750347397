import { createColorScale } from '../theme/utils/createColorScale';

export const colorPurple = createColorScale(
  ['#4965f5'],
  21,
  [0, 100],
  [-32, 19],
  [29, -11],
  'hex'
);
export const colorGrayLight = createColorScale(
  ['#e2e2ea'],
  21,
  [0, 100],
  [-10, 10],
  [-20, -1],
  'hex'
);
export const colorLDarkForeground = createColorScale(
  ['#393344'],
  21,
  [0, 100],
  [40, -20],
  [-20, 0],
  'hex'
);
export const colorLightForeground = createColorScale(
  ['#efeff1'],
  21,
  [0, 100],
  [-30, 20],
  [0, 0],
  'hex'
);
// Dark mode
export const colorPink = createColorScale(
  ['#ba09b8'],
  21,
  [0, 100],
  [-13, 36],
  [-20, 20],
  'hex'
);
export const colorRed = createColorScale(
  ['#e00653'],
  21,
  [0, 100],
  [-13, 36],
  [-20, 20],
  'hex'
);
export const colorYellow = createColorScale(
  ['#e0af06'],
  21,
  [0, 100],
  [-13, 36],
  [-20, 20],
  'hex'
);
export const colorPurplDark = createColorScale(
  ['#250863'],
  21,
  [0, 100],
  [-13, 5],
  [-20, 15],
  'hex'
);
